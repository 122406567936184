import useDownloader from "react-use-downloader";
import Button from "./ui/Button";

export default function BulkUploadInstructions() {
  const { download } = useDownloader();
  return (
    <>
      <div className="flex sm:flex-row flex-col sm:items-center justify-between sm:gap-0 gap-3">
        <h1 className="text-2xl font-semibold text-gray-900">
          Bulk User Creation
        </h1>
        <Button
          className="w-max lg:block hidden"
          onClick={() => {
            download("/sample.xlsx", "sample.xlsx");
          }}
        >
          Downlaod Sample File
        </Button>
      </div>
      <div className="mt-4">
        <h2 className="text-lg font-medium text-gray-900">
          Instruction for Bulk User Creation
        </h2>

        <div className="ml-2">
          <ol className="list-decimal ml-4 font-semibold flex  flex-col gap-4">
            <li className="flex flex-col lg:flex-row gap-5 items-start lg:items-center justify-between">
              <div>
                <h5 className="text-base mb-2 font-semibold text-gray-900">
                  XLSX or CSV File Format
                </h5>
                <p className="ml-2 text-sm text-gray-500">
                  {" "}
                  The CSV or XLSX file should contain the following columns in
                  the specified order:
                </p>
                <p className="ml-2 text-sm text-gray-500">
                  Headers would be email, name, role, phoneNumber, offices and
                  categories:
                </p>
                <ul className="flex flex-col gap-1 mt-2 list-disc ml-8">
                  <li className="text-sm text-gray-500">
                    Email: The user's email address (must be a valid email
                    format).
                  </li>
                  <li className="text-sm text-gray-500">
                    Name: The user's full name.
                  </li>
                  <li className="text-sm text-gray-500  ">
                    Role: The user's role in the system. The allowed roles are:
                    <ul className="flex flex-col gap-1 mt-2 list-disc ml-4">
                      <li className="text-sm text-gray-500">
                        <span className="font-semibold">ADMIN</span> — A user
                        with administrative privileges.
                      </li>
                      <li className="text-sm text-gray-500">
                        {" "}
                        <span className="font-semibold">AGENT</span> — A user
                        with agent-level access.
                      </li>
                    </ul>
                  </li>
                  <li className="text-sm text-gray-500">
                  Phone Number: The user's complete phone number including country code.
                  </li>

                  <li className="text-sm text-gray-500">
                    Offices: The offices existing in the workspace.
                  </li>
                  <div className=" ml-4">
                    <li className="text-sm text-gray-500">
                      <span className="font-semibold">ADMIN</span> — This user
                      can be assigned one or multiple offices (separated by
                      commas).
                    </li>
                    <li className="text-sm text-gray-500">
                      <span className="font-semibold">AGENT</span> — This user
                      can only be assigned one office.
                    </li>
                  </div>

                  <li className="text-sm text-gray-500">
                    Categories: The categories existing in the workspace.
                  </li>
                  <div className="ml-4">
                    <li className="text-sm text-gray-500">
                      <span className="font-semibold">ADMIN</span> — This user
                      can be assigned one or multiple categories (separated by
                      commas).
                    </li>
                    <li className="text-sm text-gray-500">
                      <span className="font-semibold">AGENT</span> — This user
                      will not have any categories.
                    </li>
                  </div>
                </ul>
                <p className="mt-2 ml-2 text-sm text-gray-500">
                  <span className="text-red-600">Note:</span> The headers Name, Email, and Role, along with at least one user record, are mandatory for an XLSX or CSV file to be considered valid for submission.
                </p>
              </div>
              <div className="w-full md:w-1/2 xl:w-1/2">
                <Button
                  className="w-max lg:hidden block mb-5"
                  onClick={() => {
                    download("/sample.xlsx", "sample.xlsx");
                  }}
                >
                  Downlaod Sample File
                </Button>
                <img
                  src="/01.png"
                  alt="CSV XLSX Example"
                  className="w-full h-auto  rounded-lg"
                />
              </div>
            </li>
            <li className="">
              <h5 className="text-base mb-2 font-semibold text-gray-900">
                Previewing User Details and Assigning Offices
              </h5>
              <p className="ml-1 mb-1 text-sm text-gray-500">
                {" "}
                Review the user's details such as their name, email, and role.
              </p>
              <p className="ml-3 text-base text-gray-900">For Admins:</p>
              <ul className="flex flex-col gap-1 mt-2 list-disc ml-9">
                <li className="text-sm text-gray-500">
                  Choose the user you want to assign offices to.
                </li>
                <li className="text-sm text-gray-500">
                  In the “Offices” section, you will see a list of available
                  offices (You can select multiple offices for the user by
                  checking the box next to each office name).
                </li>
                <li className="text-sm text-gray-500">
                  In the “Categories” section, after selecting an office, you
                  can assign one or more categories (Select the categories that
                  the user should belong to within each office).
                </li>
              </ul>

              <p className="ml-3 text-base text-gray-900">For Agents:</p>
              <ul className="flex flex-col gap-1 mt-2 list-disc ml-9">
                <li className="text-sm text-gray-500">
                  Step 1: Choose the user you want to assign an office to.
                </li>
                <li className="text-sm text-gray-500">
                  Step 2: In the “Office” section, you will see a list of
                  available offices (Select only an office from the list).
                </li>
              </ul>
            </li>
            <li className="">
              <h5 className="text-base mb-2 font-semibold text-gray-900">
                Final Review and User Creation
              </h5>
              <ul className="flex flex-col gap-1 mt-2 list-disc ml-8">
                <li className="text-sm text-gray-500">
                  After assigning offices and categories (for Admins), you will
                  be prompted to review the user's details, including their
                  name, email, role, offices, and categories (for Admins).
                </li>
                <li className="text-sm text-gray-500">
                  Once you've verified the information, you can proceed with the
                  user creation by clicking on Submit Button.
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
}
