import { useSearchParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ResetPassword from "./ResetPassword";
import VerifyEmail from "./VerifyEmail";
import Button from "@/components/ui/Button";
import { getAuth, checkActionCode } from "firebase/auth";

export default function AuthActions() {
  const auth = getAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mode = searchParams.get("mode");
  const oobCode = searchParams.get("oobCode");
  const [isValidCode, setIsValidCode] = useState(true);
  const [isloading, setIsLoading] = useState(true);

  useEffect(() => {
    const validateOobCode = async () => {
      if (!oobCode) {
        console.error("oobCode is missing.");
        return;
      }
      try {
        setIsLoading(true);
        const actionCodeInfo = await checkActionCode(auth, oobCode);
        setIsValidCode(true);
      } catch (error) {
        setIsValidCode(false);
      } finally {
        setIsLoading(false);
      }
    };

    validateOobCode();
  }, [auth, oobCode, navigate]);

  return (
    <>
      {isloading ? (
        <div className="h-screen w-full flex justify-center items-center">
          <span
            className="authentication-loader
authentication-loader scale-150"
          ></span>
        </div>
      ) : isValidCode ? (
        <>
          {mode === "resetPassword" && isValidCode && (
            <ResetPassword setIsValidCode={setIsValidCode} />
          )}
          {mode === "verifyEmail" && isValidCode && <VerifyEmail />}
        </>
      ) : (
        <>
          <div className="min-h-screen flex items-center justify-center bg-[#0A0B1A] py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-lg w-full space-y-8 text-center">
              <div className="border border-white/20 px-10 py-10 rounded-3xl">
                <div>
                  <img
                    width={400}
                    className="mx-auto mb-6"
                    src="/images/victoria.png"
                    alt="logo"
                  />
                </div>
                <h1 className="text-2xl font-bold text-white mb-4">
                  {" "}
                  Link Expired!
                </h1>
                <p className="text-gray-400 mb-8">
                  The password reset link you clicked has expired. Please
                  request a new one by using the 'Forgot Password' option again.
                </p>
                <Button onClick={() => navigate("/reset-password")}>
                  Resend Email
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
