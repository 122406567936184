import { LogOut, Search, User } from "lucide-react";
import Button from "./ui/Button";
import { useAuth } from "../context/AuthContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NotificationBell from "./NotificationBell";
import { useRequests } from "@/context/RequestsContext";
import { useState } from "react";
import Modal from "./ui/Modal";

const SHOW_Search = ["/dashboard", "/dashboard/requests"];
export default function Header({otherClasses,}:{otherClasses?:string}) {
  const {
    logout,
    state: { user },
  } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { state: requestState, dispatch } = useRequests();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const handleChange = (e: any) => {
    if (e?.target?.value?.length > 0) {
      dispatch({ type: "SEARCH", payload: { value: e.target.value } });
      const mainContainer = document.querySelector("#main-content");

      var targetPositionContainer = document.querySelector(
        "#admin-dashboard-requests"
      ) as HTMLDivElement;
      var topPos = targetPositionContainer?.offsetTop;
      if (
        mainContainer &&
        targetPositionContainer &&
        pathname === "/dashboard"
      ) {
        console.log({ topPos });
        mainContainer.scrollTop = topPos - 150;
      }
    } else {
      dispatch({ type: "REMOVE_SEARCH" });
    }
  };

  return (
    <header
      className={`border-b fixed top-0 left-0 w-full  lg:left-auto lg:right-0 z-40  ${
        user?.accountStatus === "PENDING" ? "border-none " : "border-gray-200 bg-white pl-16 lg:pl-0 "
      } ${otherClasses}`}
    >
      <div className="relative w-full ">
        <div className="flex py-3 items-center justify-between px-4 gap-2 xs:flex-row w-full " >
          <div className="flex">
            {user?.accountStatus === "ACTIVE" ? (
              SHOW_Search?.includes(pathname) ? (
                <div className="flex w-full max-w-lg items-center">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full mr-0">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <Search
                        className="h-5 w-5 text-gray-400 "
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      onChange={(e) => handleChange(e)}
                      id="search"
                      name="search"
                      className="block w-full rounded-md border  focus-visible:outline-0 py-1.5 pl-10 pr-3 text-gray-900  border-inset border-gray-300 placeholder:text-gray-400 focus:border-2 focus:border-inset focus:border-blue-600 sm:text-sm sm:leading-6"
                      placeholder="Search requests..."
                      type="search"
                    />
                  </div>
                </div>
              ) : (
                <></>
              )
            ) : (
              <Link to="/dashboard">
                <img
                  src="/images/victoria.png"
                  alt="Victoria"
                  className="sm:h-12 h-8 w-aut0"
                  style={{
                    objectFit: "contain",
                    filter: "drop-shadow(0 0 20px rgba(65, 105, 225, 0.3))",
                  }}
                />
              </Link>
            )}
          </div>
          <div className="flex items-center space-x-4">
            {user?.accountStatus === "ACTIVE" && (
              <>
                <NotificationBell />
                <Button
                  onClick={() => navigate("/dashboard/settings")}
                  variant="outline"
                  size="sm"
                >
                  <User className="h-4 w-4" />
                </Button>
              </>
            )}
            <Button
              className="group"
              variant="outline"
              size="sm"
              onClick={() => setIsModalOpen(true)}
            >
              <LogOut
                className={`h-4 w-4 ${
                  user?.accountStatus === "PENDING"
                    ? "stroke-white group-hover:stroke-black"
                    : " stroke-black"
                }`}
              />
            </Button>
          </div>
        </div>
        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            title={""}
          >
            <p>Are you sure you want to logout?</p>
            <div className="flex items-center justify-end gap-3 mt-4">
              <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
              <Button onClick={handleLogout}>Confirm</Button>
            </div>
          </Modal>
        )}
      </div>
    </header>
  );
}
