import { useEffect, useState } from "react";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "@/lib/firebase/app";
import { User } from "@/lib/types";
import SuperAdminTable from "@/components/SuperAdminsTable";
import { useAuth } from "@/context/AuthContext";
import { useLocation } from "react-router-dom";

const SuperAdmins = () => {
  const {state} = useAuth();
  const [superAdmins, setSuperAdmins] = useState<User[]>([]);
  const location = useLocation();

  useEffect(() => {
    const getAllSuperAdmins = async () => {
      const querry = query(
        collection(db, "users"),
        where("role", "==", "SUPER_ADMIN")
      );

      const unsubUsers = onSnapshot(
        querry,
        (snapshot: any) => {
          const users = snapshot.docs.map((doc: any) => ({
            id: doc.id,
            ...doc.data(),
          })) as any;
          setSuperAdmins(users)
        },
        (error: any) => {
          console.error("Error loading offices:", error);
        }
      );

      return () => {
        unsubUsers();
      };
    };
    if(!state?.isAuthenticated) return

    getAllSuperAdmins();
  }, [state?.isAuthenticated]);


  
  // Prevent rendering hooks conditionally by refactoring useEffect
  useEffect(() => {
    const scrollToId = location?.state?.scrollToId;
    if (scrollToId) {
      const elem = document.getElementById(scrollToId);
      if (elem) {
        setTimeout(() => {
          elem.scrollIntoView({
            behavior: "smooth",
          });
        }, 100);
      }
    }
  }, [location.state?.scrollToId]);  // Ensures the hook is stable
  
  return <SuperAdminTable id={location.state?.scrollToId} superAdmins={superAdmins} />;
};

export default SuperAdmins;
