import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@/components/ui/Button";
import { register } from "@/lib/auth";
import { useAuth } from "@/context/AuthContext";
import { CustomToast } from "@/utils/helpers";
import PhoneNumberInput from "@/components/PhoneInput";

export default function Register() {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [formData, setFormData] = useState({
    email: "",
    name: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    optIn: false,
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);

    try {
      // Basic validation
      if (!formData.email || !formData.name || !formData.password || !formData.phoneNumber) {
        throw new Error("All fields are required");
      }

      if (formData.password !== formData.confirmPassword) {
        throw new Error("Passwords do not match");
      }

      if (formData.password.length < 8) {
        throw new Error("Password must be at least 8 characters long");
      }

      await register({
        ...formData,
      });

      CustomToast(
        "success",
        "Thank you for signing up! Please check your inbox for a verification email"
      );
      navigate("/login");
    } catch (err: any) {
      console.error("Registration error:", err);
      setError(err?.message || "Registration failed");
      // CustomToast('error',err?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const isMobile = window.innerWidth < 768;
    if (isMobile && error) {
      scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [error])
  

  return (
    <div className="min-h-screen bg-[#0A0B1A] flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h1 className="text-center text-4xl font-bold tracking-tight">
          <span className="bg-gradient-to-r from-[#4169E1] to-[#9370DB] text-transparent bg-clip-text">
            Join Victoria
          </span>
        </h1>
        <h2 className="mt-6 text-center text-2xl font-bold text-white">
          Create your account
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white/10 py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {error && (
            <div className="mb-4 rounded-md bg-red-50 p-4">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          )}
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-200"
              >
                Email address
              </label>
              <input
                id="email"
                type="email"
                required
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                className="mt-2 py-2 px-3 block w-full rounded-md border border-gray-700 bg-gray-800 text-white shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-200"
              >
                Full name
              </label>
              <input
                id="name"
                type="text"
                required
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                className="mt-2 py-2 px-3 block w-full rounded-md border border-gray-700 bg-gray-800 text-white shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div className="w-full">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-200"
              >
                Phone Number
              </label>
              <PhoneNumberInput
                required={true}
                handleChange={(phone) =>
                  setFormData({ ...formData, phoneNumber: phone })
                }
                name="phoneNumber"
                value={formData.phoneNumber}
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-200"
              >
                Password
              </label>
              <input
                id="password"
                type="password"
                required
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                className="mt-2 py-2 px-3 block w-full rounded-md border border-gray-700 bg-gray-800 text-white shadow-sm focus:border-blue-500 focus:ring-blue-500"
                minLength={8}
              />
            </div>

            <div>
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium text-gray-200"
              >
                Confirm password
              </label>
              <input
                id="confirmPassword"
                type="password"
                required
                value={formData.confirmPassword}
                onChange={(e) =>
                  setFormData({ ...formData, confirmPassword: e.target.value })
                }
                className="mt-2 py-2 px-3 block w-full rounded-md border border-gray-700 bg-gray-800 text-white shadow-sm focus:border-blue-500 focus:ring-blue-500"
                minLength={8}
              />
            </div>
            <div className="flex items-start gap-3">
              <input
                id="opt-in"
                type="checkbox"
                required
                checked={formData?.optIn}
                onChange={(e) =>
                  setFormData({ ...formData, optIn: e.target.checked })
                }
                className="mt-[0.5px] sm:h-5 sm:w-5 w-6 h-6"
              />
              <label
                htmlFor="opt-in"
                className="block text-sm font-medium text-gray-200"
              >
                I agree to receive messages/notifications on my phone number. 
              </label>
            </div>
            <div>
              <Button
                type="submit"
                className="w-full flex justify-center py-2 px-4"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Creating account..." : "Create account"}
              </Button>
            </div>
          </form>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-700" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="bg-[#0A0B1A] px-2 text-gray-400">
                  Already have an account?
                </span>
              </div>
            </div>

            <div className="mt-6">
              <Button
                variant="outline"
                className="w-full text-white"
                onClick={() => navigate("/login")}
              >
                Sign in
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
