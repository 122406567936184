import { useState } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

import "./CustomPhoneInput.scss";
import clsx from "clsx";
const PhoneNumberInput = ({
  variant = "primary",
  name,
  value,
  handleChange,
  required = false,
}: {
  value: string;
  handleChange: (phone: string) => void;
  name: string;
  required: boolean;
  variant?: "primary" | "secondary";
}) => {
  const defaultValue = value || "+1"; 
  return (
    <PhoneInput
      required={required}
      defaultCountry="us"
      name={name}
      disableDialCodeAndPrefix
      showDisabledDialCodeAndPrefix
      className={
        clsx(
          'w-full'
          ,
          variant === "primary"
          ? "custom-phone-input-dark"
          : "custom-phone-input-light"
        )

      }
      value={defaultValue}
      onChange={(phone) => handleChange(phone)}
    />
  );
};

export default PhoneNumberInput;
