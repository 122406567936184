import Button from "@/components/ui/Button";
import { Link, useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-[#0A0B1A] text-sm text-[#d1d5db] isolate px-6 pt-8 lg:px-8 relative min-h-screen flex items-center justify-center overflow-hidden">
      <div className="absolute inset-x-0 -top-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
        <div className="relative right-0 aspect-[1155/678] w-1/2 rotate-[30deg] bg-gradient-to-tr from-[#4169E1] to-[#9370DB] opacity-30 sm:left-1/2 sm:w-[72.1875rem]" />
      </div>

      <div className="mx-auto max-w-5xl  py-20 lg:py-0 ">
        <div className="text-center">
          <div className="flex justify-center mb-8">
              <Link to="/dashboard">
            <img
              src="/images/victoria.png"
              alt="Victoria"
              className="h-32 w-auto"
              style={{
                objectFit: "contain",
                filter: "drop-shadow(0 0 20px rgba(65, 105, 225, 0.3))",
              }}
            />
            </Link>
          </div>
          <h1 className="text-center text-4xl font-bold tracking-tight">
            <span className="bg-gradient-to-r from-[#4169E1] to-[#9370DB] text-transparent bg-clip-text">
              Oops! The page you’re looking for doesn’t exist or might have been
              moved.
            </span>
          </h1>
          <p className="mt-6 lg:mt-12 text-2xl sm:text-3xl leading-relaxed text-gray-300 max-w-4xl mx-auto">
          The page you’re looking for isn’t available or might have been moved. You can go back to home by clicking the "Back to Home" button or the Victoria logo. If this seems like a mistake, contact support.
          </p>
          <div className="mt-8 lg:mt-16 flex items-center justify-center flex-wrap gap-y-6 gap-x-8">
            <Button
              onClick={() => navigate("/login")}
              size="lg"
              className="text-xl px-12 py-6  "
            >
              Back to home
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
