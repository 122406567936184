import { useEffect, useState } from "react";
import { useAuth } from "@/context/AuthContext";
import { useAdmin } from "@/context/AdminContext";
import Button from "@/components/ui/Button";
import Modal from "@/components/ui/Modal";
import { UserCog, Pencil, Trash } from "lucide-react";
import { User } from "@/lib/types";
import { canAccessAdminRoutes } from "@/lib/permissions";
import { Select, Space } from "antd";
import { createUser, deleteUser } from "@/api/user";
import { resetPassword } from "@/lib/auth";
import clsx from "clsx";
import { CustomToast } from "@/utils/helpers";
import { useLocation } from "react-router-dom";
import ButtonLoader from "./ui/ButtonLoader";
import TableScrollWrapper from "./TableScrollWrapper";
import PhoneNumberInput from "./PhoneInput";
import { updateUser } from "@/api/user";
import { insertNotification } from "@/lib/firebase/notification";

const INITIAL_STATE = {
  email: "",
  name: "",
  role: "",
  officeIds: [] as string[],
  categoryIds: [] as string[],
  phoneNumber: "",
};

export default function UserManagement() {
  const { state: adminState, dispatch: adminDispatch } = useAdmin();
  const { state: authState, insertUser, getToken } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState(INITIAL_STATE);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [deletingUserId, setDeletingUserId] = useState<null | number | string>(
    null
  );

  const location = useLocation();

  const currentUser = authState.user;
  if (!currentUser || !canAccessAdminRoutes(currentUser)) {
    return null;
  }

  const isSuperAdmin = currentUser.role === "SUPER_ADMIN";

  // Filter users based on admin permissions and sort by name
  const sortedUsers = [...adminState.users]
    .filter((user) => {
      if (isSuperAdmin) return true;
      if (currentUser.role === "ADMIN") {
        return (
          user.role !== "SUPER_ADMIN" &&
          user.officeIds &&
          user.officeIds.some(
            (id) => currentUser.officeIds && currentUser.officeIds.includes(id)
          )
        );
      }
      return false;
    })
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

  const handleEditSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    try {
      if (formData?.officeIds?.length === 0 || !formData?.officeIds) {
        throw new Error("Please select at least one office");
      }

      // If regular admin is creating/editing, force role to AGENT
      const role = isSuperAdmin ? formData.role : "AGENT";

      // For admins, ensure they have categories assigned
      if (role === "ADMIN" && formData.categoryIds.length === 0) {
        throw new Error("Please assign at least one category to the admin");
      }

      const newOfficeIds = formData?.officeIds || [];
      const newCategoryIds = formData?.categoryIds || [];
      
      const previousOfficeIds = editingUser?.officeIds || [];
      const previousCategoryIds = editingUser?.categoryIds || [];

    
      // Check for new office assignments
      const newlyAssignedOffices = newOfficeIds.filter(
        (officeId) => !previousOfficeIds.includes(officeId)
      );

      // Check for new office assignments
      const newlyAssignedCategories = newCategoryIds.filter(
        (categoryId) => !previousCategoryIds.includes(categoryId)
      );

       // Check for removed office assignments
      const removedOffices = previousOfficeIds.filter(
        (officeId) => !newOfficeIds.includes(officeId)
      );

        // Check for removed office assignments
      const removedCategories = previousCategoryIds.filter(
        (officeId) => !newCategoryIds.includes(officeId)
      );
    

      // Create the user object
      const user: User = {
        id: editingUser?.id || crypto.randomUUID(),
        email: formData.email,
        name: formData.name,
        role,
        officeIds: formData.officeIds,
        categoryIds: role === "ADMIN" ? formData.categoryIds : [],
        createdAt: editingUser?.createdAt || new Date().toISOString(),
        phoneNumber: formData.phoneNumber,
      };
      setIsLoading(true);
      const res = await updateUser({
        token: (await getToken()) as string,
        body: {
          name: user.name,
          phoneNumber: user.phoneNumber,
          role: user.role,
          categoryIds: user.categoryIds,
          officeIds: user.officeIds,
          type: "updateUser",
          userId: user.id,
        },
      });
      if (res.status !== 200) {
        setIsLoading(false);
        const errorText = await res.json();
        throw new Error(errorText.message);
      }
      CustomToast("success", "User updated successfully");
      
      const updatedUser = adminState.users.find(
        (u) => u.id === user.id
      );
      console.log({ updatedUser });
      const superAdmin = adminState?.assosicatedAdmins?.find((u) => u.role === "SUPER_ADMIN" && u.id === updatedUser?.workspaceId);
         
      if (authState?.user?.role === "SUPER_ADMIN" || authState?.user?.role === "ADMIN") {
        const allNotificationPromise: any = [];
        if (removedOffices.length > 0 && updatedUser?.role  !== "AGENT") {
          removedOffices.forEach((officeId) => {
            allNotificationPromise.push(
              insertNotification({
                title: "Office Removed",
                message: `You've been removed from the office: "${getOfficeName(officeId)}".`,
                userId: updatedUser?.id as string,
                workspaceId: updatedUser?.workspaceId as string,
                key: "users",
              })
            );
          });
        }
        if (removedCategories.length > 0 && authState?.user?.role === "SUPER_ADMIN") {
          removedCategories.forEach((categoryId) => {
            allNotificationPromise.push(
              insertNotification({
                title: "Category Removed",
                message: `You've been removed from the category: "${getCategoryName(categoryId)}".`,
                userId: updatedUser?.id as string,
                workspaceId: updatedUser?.workspaceId as string,
                key: "users",
              })
            );
          });
        }
        if (newlyAssignedOffices.length > 0 && updatedUser?.role  !== "AGENT") {
          newlyAssignedOffices.forEach((officeId) => {
            allNotificationPromise.push(
              insertNotification({
                title: "New Office Assigned",
                message: `You've been assigned a new office: "${getOfficeName(officeId)}".`,
                userId: updatedUser?.id as string,
                workspaceId: updatedUser?.workspaceId as string,
                key: "users",
              })
            );
          });
        }
        if (newlyAssignedCategories.length > 0 && authState?.user?.role === "SUPER_ADMIN") {
          newlyAssignedCategories.forEach((categoryId) => {
            allNotificationPromise.push(
              insertNotification({
                title: "New Category Assigned",
                message: `You've been assigned a new category: "${getCategoryName(categoryId)}".`,
                userId: updatedUser?.id as string,
                workspaceId: updatedUser?.workspaceId as string,
                key: "users",
              })
            );
          });
        }  
        if ((newlyAssignedOffices.length > 0 && removedOffices?.length > 0) && updatedUser?.role === "AGENT") {
          allNotificationPromise.push(
            insertNotification({
              title: "Office Assignment Updated",
              message: `Your office: "${getOfficeName(removedOffices[0])}" has been replaced with office: "${getOfficeName(newlyAssignedOffices[0])}" by ${authState?.user?.name}.`,
              userId: updatedUser?.id as string,
              workspaceId: updatedUser?.workspaceId as string,
              key: "users",
            })
          );
        }
        if ((newlyAssignedOffices.length > 0 && removedOffices?.length > 0) && updatedUser?.role === "AGENT" && authState?.user?.role === "ADMIN") {
          allNotificationPromise.push(
            insertNotification({
              title: "Office Assignment Updated",
              message: `${authState?.user?.name} has replaced ${updatedUser?.name}'s office: "${getOfficeName(removedOffices[0])}" with office: "${getOfficeName(newlyAssignedOffices[0])}".`,
              userId: superAdmin?.id as string,
              workspaceId: superAdmin?.id as string,
              key: "users",
            })
          );
        }
        await Promise.all(allNotificationPromise);
      }
      setIsLoading(false);
      setIsModalOpen(false);
      setFormData(INITIAL_STATE);
    } catch (error) {
      setError(error instanceof Error ? error.message : "Failed to save user");
    }
  };

  useEffect(() => {
    setError(null);
  }, [isModalOpen]);

  useEffect(() => {
    const scrollToId = location.state?.scrollToId;

    const elem = document.getElementById(scrollToId);

    if (scrollToId && elem) {
      setTimeout(() => {
        elem.scrollIntoView({
          behavior: "smooth",
        });

        window.history.replaceState({}, document.title);
      }, 100);
    }
  }, [location.state?.scrollToId]);

  const handleAdditionSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    setIsLoading(true); // Optionally show a loading indicator
    try {
      if (formData?.officeIds?.length === 0 || !formData.officeIds) {
        throw new Error("Please select at least one office");
      }

      // If regular admin is creating/editing, force role to AGENT
      const role = isSuperAdmin ? formData.role : "AGENT";

      // For admins, ensure they have categories assigned
      if (role === "ADMIN" && formData.categoryIds.length === 0) {
        throw new Error("Please assign at least one category to the admin");
      }
      if (
        !formData.phoneNumber ||
        !formData?.email ||
        !formData?.name ||
        !role
      ) {
        throw new Error("All fields required");
      }
      const token = (await getToken()) as string;

      const res: any = await createUser({
        token,
        body: {
          name: formData?.name,
          email: formData?.email,
          officeIds: formData?.officeIds,
          categoryIds: formData?.categoryIds,
          role: role,
          workspaceId: currentUser?.workspaceId,
          createdByRole: currentUser?.role,
          createdByName: currentUser?.name,
          phoneNumber: formData.phoneNumber,
        },
      });
      if (res.status !== 200) {
        const errorText = await res.json();
        throw new Error(errorText.message);
      }
      CustomToast("success", "User created successfully");
      setIsLoading(false);
      setIsModalOpen(false);
      setFormData(INITIAL_STATE);
    } catch (error: any) {
      setError(error?.message);
      setIsLoading(false);
    }
  };

  const handleEdit = (user: User) => {
    setEditingUser(user);
    setFormData({
      email: user.email,
      name: user.name,
      role: user.role,
      officeIds: user.officeIds || [],
      categoryIds: user.categoryIds || [],
      phoneNumber: user.phoneNumber || "",
    });
    setIsModalOpen(true);
  };

  const handleDelete = async () => {
    if (!deletingUserId) {
      return CustomToast("error", "Failed to detect user Id");
    }
    setIsLoading(true);
    try {
      const token = (await getToken()) as string;
      await deleteUser({ id: deletingUserId, token });
      CustomToast("success", "User deleted successfully");
    } catch (error) {
      CustomToast("error", "Error deleting user");
      console.error("Error deleting user:", error);
      CustomToast(
        "error",
        error instanceof Error ? error.message : "Failed to delete user"
      );
    }
    setIsLoading(false);
    closeDeleteModal();
  };

  const getOfficeName = (officeId: string): string => {
    const office = adminState?.offices?.find(
      (office: Record<string, any>) => office?.id === officeId
    );
    return office?.name || "";
  };

  const getCategoryName = (categoryId: string): string => {
    const category = adminState?.categories?.find(
      (category: Record<string, any>) => category?.id === categoryId
    );
    return category?.name || "";
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDeletingUserId(null);
  };

  useEffect(() => {
    if (
      editingUser?.email !== formData.email ||
      editingUser?.name !== formData.name ||
      editingUser?.phoneNumber !== formData.phoneNumber ||
      editingUser?.categoryIds !== formData.categoryIds ||
      editingUser?.officeIds !== formData.officeIds
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [editingUser, formData]);

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-medium text-gray-900">Team Members</h2>
        <Button
          onClick={() => {
            setEditingUser(null);
            setIsModalOpen(true)
          }}
          disabled={isLoading || adminState?.offices?.length === 0}
        >
          Add User
        </Button>
      </div>

      <div className="mt-4">
        {sortedUsers.length === 0 ? (
          <div className="rounded-lg bg-white p-8 text-center">
            <UserCog className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              No users
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Get started by creating a new user.
            </p>
          </div>
        ) : (
          <TableScrollWrapper classes="overflow-x-auto">
            <div className={clsx(isLoading ? "pointer-events-none" : "")}>
              <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr className="!text-red-200">
                        <th
                          scope="col"
                          className={clsx(
                            "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6",
                            isLoading ? "!text-gray-500" : ""
                          )}
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className={clsx(
                            "px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
                            isLoading ? "!text-gray-500" : ""
                          )}
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className={clsx(
                            "px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
                            isLoading ? "!text-gray-500" : ""
                          )}
                        >
                          Role
                        </th>
                        <th
                          scope="col"
                          className={clsx(
                            "px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
                            isLoading ? "!text-gray-500" : ""
                          )}
                        >
                          Office
                        </th>
                        {isSuperAdmin && (
                          <th
                            scope="col"
                            className={clsx(
                              "px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
                              isLoading ? "!text-gray-500" : ""
                            )}
                          >
                            Categories
                          </th>
                        )}
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Actions</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {sortedUsers.map((user) => (
                        <tr
                          key={user.id}
                          id={user?.id}
                          className={
                            location?.state?.scrollToId === user?.id
                              ? "!bg-gray-200/50 !border-r-gray-300 border !border-l-gray-300"
                              : ""
                          }
                        >
                          <td
                            className={clsx(
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
                              isLoading ? "!text-gray-500" : ""
                            )}
                          >
                            {user.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {user.email}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {user.role}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {user?.officeIds
                              ?.map((officeId) => getOfficeName(officeId))
                              .join(", ")}
                          </td>
                          {isSuperAdmin && (
                            <td className="min-w-48 max-w-96  whitespace-wrap px-3 py-4 text-sm text-gray-500">
                              {user?.categoryIds
                                ?.map((categoryId) =>
                                  getCategoryName(categoryId)
                                )
                                .join(", ")} 
                            </td>
                          )}
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <div className="flex justify-end space-x-2">
                              <Button
                                variant="outline"
                                size="sm"
                                onClick={() => handleEdit(user)}
                              >
                                <Pencil className="h-4 w-4" />
                              </Button>
                              {currentUser?.role === "SUPER_ADMIN" && (
                                <Button
                                  variant="outline"
                                  size="sm"
                                  onClick={() => {
                                    setDeletingUserId(user.id);
                                    setIsDeleteModalOpen(true);
                                  }}
                                  // onClick={() => handleDelete(user.id)}
                                >
                                  <Trash className="h-4 w-4" />
                                </Button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </TableScrollWrapper>
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setFormData(INITIAL_STATE);
          setError(null);
        }}
        title={editingUser ? "Edit User" : "Add User"}
      >
        <form
          onSubmit={(e) => {
            if (editingUser) {
              handleEditSubmit(e);
            } else {
              handleAdditionSubmit(e);
            }
          }}
          className="space-y-4"
        >
          {error && (
            <div className="rounded-md bg-red-50 p-4">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          )}

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              disabled={!!(editingUser && editingUser?.id) || isLoading}
              type="email"
              id="email"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              className="mt-1 px-1 py-2 lock w-full rounded-md border-border-default shadow-sm focus:border-blue-antd focus-visible:border-blue-antd focus-visible:outline-0   border focus:ring-blue-antd sm:text-sm disabled:cursor-not-allowed"
              required
            />
          </div>
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              className="mt-1 px-1 py-2 lock w-full rounded-md border-border-default shadow-sm focus:border-blue-antd focus-visible:border-blue-antd focus-visible:outline-0   border focus:ring-blue-antd sm:text-sm"
              required
              disabled={isLoading}
            />
          </div>

          <div>
            <label
              htmlFor="phoneNumber"
              className="block text-sm font-medium text-gray-700"
            >
              Phone Number
            </label>
            <PhoneNumberInput
              required={true}
              value={formData?.phoneNumber}
              handleChange={(e) => setFormData({ ...formData, phoneNumber: e })}
              name="phoneNumber"
              variant="secondary"
            />
          </div>
          {isSuperAdmin && (
            <div>
              <label
                htmlFor="role"
                className="block text-sm font-medium text-gray-700"
              >
                Role
              </label>
              <Space style={{ width: "100%" }} direction="vertical">
                <Select
                  disabled={!!(editingUser && editingUser?.id) || isLoading}
                  className="w-full mt-1"
                  placeholder="Please select role"
                  defaultValue={formData?.role || ""}
                  onChange={(value) => {
                    if (value === "AGENT") {
                      setFormData((prev) => ({
                        ...prev,
                        officeIds: [],
                        categoryIds: [],
                      }));
                    }
                    setFormData((prev: any) => ({ ...prev, role: value }));
                  }}
                  options={[
                    {
                      label: "Agent",
                      value: "AGENT",
                    },
                    {
                      label: "Admin",
                      value: "ADMIN",
                    },
                  ]}
                />
              </Space>
            </div>
          )}
          <div>
            <label
              htmlFor="offices"
              className="block text-sm font-medium text-gray-700"
            >
              Offices
            </label>
            <Space className="w-full" direction="vertical">
              <Select
                disabled={isLoading}
                mode={formData?.role === "ADMIN" ? "multiple" : undefined}
                allowClear
                className="w-full mt-1"
                placeholder="Please select offices"
                value={formData?.officeIds || []}
                defaultValue={formData?.officeIds || []}
                onChange={(value) => {
                  if (
                    (formData?.role === "AGENT" ||
                      currentUser?.role === "ADMIN") &&
                    typeof value === "string"
                  )
                    return setFormData((prev) => ({
                      ...prev,
                      officeIds: [value],
                    }));
                  setFormData((prev) => ({ ...prev, officeIds: value }));
                }}
                options={adminState?.offices?.map((office: any) => {
                  return {
                    label: office?.name,
                    value: office?.id,
                  };
                })}
              />
            </Space>
          </div>
          {isSuperAdmin && formData.role === "ADMIN" && (
            <div>
              <label
                htmlFor="categories"
                className="block text-sm font-medium text-gray-700"
              >
                Categories
              </label>

              <Space style={{ width: "100%" }} direction="vertical">
                <Select
                  disabled={isLoading}
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Please select categories"
                  defaultValue={formData?.categoryIds || []}
                  onChange={(value) =>
                    setFormData((prev) => ({ ...prev, categoryIds: value }))
                  }
                  options={adminState?.categories
                    .map((office: any) => {
                      return {
                        label: office?.name,
                        value: office?.id,
                      };
                    })}
                />
              </Space>
            </div>
          )}
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <Button
              type="submit"
              className="w-full sm:ml-3 sm:w-auto"
              disabled={isLoading || disabled}
            >
              {editingUser ? "Update" : "Create"}

              {isLoading && <ButtonLoader />}
            </Button>
            <Button
              type="button"
              variant="outline"
              onClick={() => {
                setIsModalOpen(false);
                setFormData(INITIAL_STATE);
                setError(null);
              }}
              disabled={isLoading}
              className="mt-3 w-full sm:mt-0 sm:w-auto"
            >
              Cancel
            </Button>
          </div>
        </form>
      </Modal>

      {isDeleteModalOpen && (
        <Modal isOpen={isDeleteModalOpen} onClose={closeDeleteModal} title={""}>
          <p>Are you sure you want to delete this user?</p>
          <div className="flex items-center justify-end gap-3 mt-4">
            <Button onClick={closeDeleteModal} disabled={isLoading}>
              Cancel
            </Button>
            <Button onClick={handleDelete} disabled={isLoading}>
              Confirm
              {isLoading && <ButtonLoader />}
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
}
