import { ChangeEvent, useRef } from "react";
import { Upload, X } from "lucide-react";
import Button from "./Button";
import { cn } from "@/lib/utils";
import { CustomToast } from "@/utils/helpers";

interface FileUploadProps {
  onFileSelect: (files: File[]) => void;
  onRemove?: (index: number) => void;
  selectedFiles?: File[];
  multiple?: boolean;
  accept?: string;
  maxSize?: number; // in bytes
  className?: string;
}

export default function FileUpload({
  onFileSelect,
  onRemove,
  selectedFiles = [],
  multiple = true,
  accept = "*",
  maxSize = 10 * 1024 * 1024, // 10MB default
  className,
}: FileUploadProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    const validFiles = files.filter((file) => file.size <= maxSize);

    if (validFiles.length < files.length) {
      // alert(
      //   `Some files were too large. Maximum size is ${maxSize / 1024 / 1024}MB`
      // );
      CustomToast(
        "error",
        `Some files were too large. Maximum size is ${maxSize / 1024 / 1024}MB`
      );
    }

    onFileSelect(validFiles);

    // Reset input to allow selecting the same file again
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const formatFileSize = (bytes: number) => {
    if (bytes < 1024) return bytes + " B";
    if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(1) + " KB";
    return (bytes / (1024 * 1024)).toFixed(1) + " MB";
  };

  return (
    <div className={cn("space-y-4", className)}>
      <div className="flex items-center space-x-2">
        <Button
          type="button"
          variant="outline"
          size="sm"
          onClick={() => fileInputRef.current?.click()}
          className="  text-gray-500"
        >
          <Upload className="h-4 w-4 mr-2" />
          Attach Files
        </Button>
        <input
          ref={fileInputRef}
          type="file"
          className="hidden"
          onChange={handleFileSelect}
          multiple={multiple}
          accept={accept}
        />
      </div>

      {selectedFiles.length > 0 && (
        <ul className="space-y-2">
          {selectedFiles.map((file, index) => (
            <li
              key={index}
              className="flex items-center justify-between rounded-md bg-gray-50 p-2 text-sm"
            >
              <div className="flex items-center space-x-2">
                <span className="font-medium">{file.name}</span>
                <span className="text-gray-500">
                  ({formatFileSize(file.size)})
                </span>
              </div>
              {onRemove && (
                <button
                  type="button"
                  onClick={() => onRemove(index)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <X className="h-4 w-4" />
                </button>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
