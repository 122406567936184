import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/context/AuthContext";
import { useAdmin } from "@/context/AdminContext";
import Button from "@/components/ui/Button";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "@/lib/firebase";
import { InfoIcon } from "lucide-react";
import Modal from "./ui/Modal";

async function checkApiKeyAuthorization(apiKey: string) {
  try {
    const response = await fetch("https://api.openai.com/v1/models", {
      headers: { Authorization: `Bearer ${apiKey}` },
    });
    return response.ok;
  } catch (error) {
    console.error("Error checking API key:", error);
    return false;
  }
}

export default function OpenAiConfiguration() {
  const navigate = useNavigate();
  const { state: authState, dispatch: authDispatch, getToken } = useAuth();
  const { dispatch: adminDispatch } = useAdmin();
  const user = authState.user;

  const [formData, setFormData] = useState({
    openAiKey: user?.openAiKey || "",
  });
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!user) {
    navigate("/login");
    return null;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setIsSubmitting(true);

    try {
      // Validate inputs
      if (formData.openAiKey.trim()) {
        const isValid = await checkApiKeyAuthorization(formData?.openAiKey);
        const updatedUser = {
          ...user,
          openAiKey: formData?.openAiKey,
        };

        if (user && user.emailVerified) {
          if (isValid) {
            await updateDoc(doc(db, "users", user.id), {
              openAiKey: formData?.openAiKey,
            });
            adminDispatch({ type: "UPDATE_USER", payload: updatedUser });
            authDispatch({ type: "UPDATE_USER", payload: updatedUser });
            setSuccess("Open Ai Key updated successfully.");
          } else {
            setError("The API key provided is invalid.");
          }
        }
      } else {
        const updatedUser = {
          ...user,
          openAiKey: formData?.openAiKey,
        };

        if (user && user.emailVerified) {
          await updateDoc(doc(db, "users", user.id), {
            openAiKey: formData?.openAiKey,
          });
          adminDispatch({ type: "UPDATE_USER", payload: updatedUser });
          authDispatch({ type: "UPDATE_USER", payload: updatedUser });
          setSuccess("Open Ai Key updated successfully.");
        }
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred.");
    } finally {
      setIsSubmitting(false);
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    if (user.openAiKey !== formData.openAiKey) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [user, formData]);

  return (
    <>
      <form className=" border-b-[1px] pb-6 border-gray-200">
        {error && (
          <div className="rounded-md bg-red-50 p-4">
            <p className="text-sm text-red-700">{error}</p>
          </div>
        )}

        {success && (
          <div className="rounded-md bg-green-50 p-4">
            <p className="text-sm text-green-700">{success}</p>
          </div>
        )}

        <div>
          <h2 className="text-lg font-medium text-gray-900 mb-5 mt-3">
            OpenAi Configuration
          </h2>
          <label
            htmlFor="name"
            className="flex items-center gap-2 text-sm font-medium text-gray-700"
          >
            Open AI Api Key
            <div className="group relative z-10">
              <InfoIcon className="cursor-pointer" height={17} width={17} />
              <p className="absolute rounded-md top-[25px] -right-24 xs:-right-36 hidden group-hover:block min-w-[250px] xs:min-w-[300px] bg-white shadow-2xl p-3 overflow-x-clip">
                To get your OpenAI API key, sign in to your OpenAI account. Once
                logged in, navigate to the API Keys section under your account
                settings. Click the Create new secret key button to generate a
                new API key. Copy the key immediately, as it will only be shown
                once for security reasons. You can then use this key to
                authenticate API requests. Ensure you keep it secure and do not
                share it publicly.
              </p>
            </div>
          </label>
          <input
            type="text"
            id="openAiKey"
            value={formData.openAiKey}
            onChange={(e) =>
              setFormData({ ...formData, openAiKey: e.target.value })
            }
            className="mt-2 p-2 border w-full rounded-md  border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </div>

        <div className="flex justify-end pt-6">
          <Button
            type="submit"
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              setIsModalOpen(true);
            }}
          >
            {isSubmitting ? "Saving..." : "Save Changes"}
          </Button>
        </div>
      </form>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={""}
      >
        <p>
          Are you sure you want to update the key? If you update the API key,
          you will also need to update the Assistant IDs to ensure you receive
          the desired responses.
        </p>
        <div className="flex items-center justify-end gap-3 mt-4">
          <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
          <Button disabled={isSubmitting || disabled} onClick={handleSubmit}>
            Confirm
          </Button>
        </div>
      </Modal>
    </>
  );
}
