import { sendCustomMail } from "@/api/sendEmail";

interface EmailMetaData {
  title: string;
  message: string;
  sendingEmail?: string;
}

export const sendSupportEmail = async ({
  message,
  sendingEmail,
  title,
}: EmailMetaData) => {
  return await sendCustomMail({
    body: {
      template_name: "support-email-template",
      data: {
        title,
        message,
        sendingEmail,
      },
    },
  });
};
