import { useState, useEffect } from "react";
import Button from "@/components/ui/Button";
import Modal from "@/components/ui/Modal";
import { Category } from "@/lib/categories";

interface CategoryFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  editingCategory: any;
  categories: Category[];
  error: string | null;
  loading: boolean;
  isEdit: boolean;
  setEditingCategory: React.Dispatch<React.SetStateAction<any>>;
}

export default function CategoryForm({
  isOpen,
  onClose,
  onSubmit,
  editingCategory,
  categories,
  error,
  loading,
  isEdit,
  setEditingCategory,
}: CategoryFormProps) {
  const [disabled, setDisabled] = useState(false);

  const defaultCategoryIds = categories
    .filter((category) => category?.default)
    .map((category) => category?.id);
  console.log({ defaultCategoryIds });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit();
  };

  useEffect(() => {
    const category = categories?.find(
      (node) => node?.id === editingCategory?.id
    );
    if (
      editingCategory?.description !== category?.description ||
      editingCategory?.name !== category?.name ||
      editingCategory?.assistantId !== category?.assistantId
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [editingCategory, categories]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={isEdit ? "Edit Category" : "Add Category"}
    >
      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <p className="text-sm text-red-700">{error}</p>
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Name
          </label>
          <input
            disabled={
              editingCategory &&
              defaultCategoryIds?.includes(editingCategory?.id)
            }
            type="text"
            id="name"
            value={editingCategory?.name}
            onChange={(e) =>
              setEditingCategory({ ...editingCategory, name: e.target.value })
            }
            className="mt-2 px-4 py-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm border"
            required
          />
        </div>
        <div>
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700"
          >
            Description
          </label>
          <textarea
            disabled={
              editingCategory &&
              defaultCategoryIds?.includes(editingCategory?.id)
            }
            id="description"
            value={editingCategory?.description}
            onChange={(e) =>
              setEditingCategory({
                ...editingCategory,
                description: e.target.value,
              })
            }
            rows={3}
            className="mt-2 px-4 py-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm border"
            required
          />
        </div>
        <div>
          <label
            htmlFor="assistantId"
            className="block text-sm font-medium text-gray-700"
          >
            Assistant Id
          </label>
          <input
            type="text"
            id="assistantId"
            value={editingCategory?.assistantId}
            onChange={(e) =>
              setEditingCategory({
                ...editingCategory,
                assistantId: e.target.value,
              })
            }
            className="mt-2 px-4 py-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm border"
            required
          />
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <Button
            type="submit"
            className="w-full sm:ml-3 sm:w-auto"
            disabled={
              (isEdit
                ? disabled
                : (editingCategory?.description === "" ||
                  editingCategory?.name === "" ||
                  editingCategory?.assistantId === "" || editingCategory === null ||  !editingCategory?.description ||!editingCategory?.name  || !editingCategory?.assistantId )) || loading
            }
          >
            {loading ? "Submitting" : isEdit ? "Update" : "Create"}
          </Button>
          <Button
            type="button"
            variant="outline"
            onClick={onClose}
            className="mt-3 w-full sm:mt-0 sm:w-auto"
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
}
