import { 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  updateProfile
} from 'firebase/auth';
import { 
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
  enableNetwork,
  disableNetwork,
  waitForPendingWrites
} from 'firebase/firestore';
import { app, auth, db, storage, testFirebaseConnection } from './firebase/app';
import { User } from './types';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

// Retry mechanism for operations
const retryOperation = async <T>(
  operation: () => Promise<T>,
  retries = MAX_RETRIES
): Promise<T> => {
  for (let i = 0; i < retries; i++) {
    try {
      return await operation();
    } catch (error: any) {
      if (i === retries - 1 || error.code !== 'unavailable') {
        throw error;
      }
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY * Math.pow(2, i)));
    }
  }
  throw new Error('Operation failed after retries');
};

// Network status management
export const networkStatus = {
  async goOffline() {
    try {
      await waitForPendingWrites(db);
      await disableNetwork(db);
    } catch (error) {
      console.error('Error going offline:', error);
    }
  },
  async goOnline() {
    try {
      await enableNetwork(db);
    } catch (error) {
      console.error('Error going online:', error);
    }
  }
};

export async function signIn(email: string, password: string): Promise<void> {
  return retryOperation(async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error: any) {
      console.error('Sign in error:', error);
      if (error.code === 'auth/invalid-credential') {
        throw new Error('Invalid email or password');
      }
      if (error.code === 'auth/network-request-failed') {
        throw new Error('Network error. Please check your connection.');
      }
      if (error.code === 'auth/too-many-requests') {
        throw new Error('Too many failed attempts. Please try again later.');
      }
      throw new Error('Failed to sign in. Please try again.');
    }
  });
}

export async function register(data: {
  email: string;
  password: string;
  name: string;
  officeId: string;
}): Promise<User> {
  return retryOperation(async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password);
      
      await updateProfile(userCredential.user, {
        displayName: data.name
      });

      const user: User = {
        id: userCredential.user.uid,
        email: data.email,
        name: data.name,
        workspaceId:userCredential.user.uid,
        role: 'AGENT',
        officeIds: [data.officeId],
        categoryIds: [],
        createdAt: new Date().toISOString()
      };

      const userRef = doc(db, 'users', user.id);
      await setDoc(userRef, {
        ...user,
        updatedAt: serverTimestamp()
      });

      return user;
    } catch (error: any) {
      console.error('Registration error:', error);
      if (error.code === 'auth/email-already-in-use') {
        throw new Error('Email already in use');
      }
      if (error.code === 'auth/weak-password') {
        throw new Error('Password is too weak');
      }
      if (error.code === 'auth/network-request-failed') {
        throw new Error('Network error. Please check your connection.');
      }
      throw new Error('Registration failed. Please try again.');
    }
  });
}

export async function signOut(): Promise<void> {
  return retryOperation(async () => {
    try {
      await firebaseSignOut(auth);
    } catch (error) {
      console.error('Sign out error:', error);
      throw new Error('Failed to sign out. Please try again.');
    }
  });
}

export function getCurrentUser(): Promise<User | null> {
  return new Promise((resolve) => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      unsubscribe();
      
      if (!firebaseUser) {
        resolve(null);
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
        if (!userDoc.exists()) {
          resolve(null);
          return;
        }

        const userData = userDoc.data();
        console.log('Current user data:', userData);
        resolve({ id: userDoc.id, ...userData } as User);
      } catch (error) {
        console.error('Get current user error:', error);
        resolve(null);
      }
    });

    // Set a timeout to prevent hanging
    setTimeout(() => {
      unsubscribe();
      resolve(null);
    }, 10000);
  });
}

export { app, auth, db, storage, testFirebaseConnection };