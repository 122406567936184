export const CATEGORIES = {
  BROKER: 'Broker Questions',
  TECH: 'Tech Questions',
  ACCOUNTING: 'Accounting',
  MARKETING: 'Marketing',
  PAPERWORK: 'Paperwork',
  AI: 'AI Assistant',
  UNCATEGORIZED: 'Uncategorized',
} as const;

export type Category = {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  workspaceId:string;
  default?: boolean;
  assistantId?: string;
};

// Map display names to IDs
export const CATEGORY_IDS = {
  [CATEGORIES.BROKER]: 'broker-questions',
  [CATEGORIES.TECH]: 'tech-questions',
  [CATEGORIES.ACCOUNTING]: 'accounting',
  [CATEGORIES.MARKETING]: 'marketing',
  [CATEGORIES.PAPERWORK]: 'paperwork',
  [CATEGORIES.AI]: 'ai-assistant',
  [CATEGORIES.UNCATEGORIZED]: 'uncategorized',
} as const;

// export const DEFAULT_CATEGORIES: Category[] = Object.entries(CATEGORIES).map(([key, name]) => ({
//   id: CATEGORY_IDS[name as keyof typeof CATEGORY_IDS],
//   name,
//   description: `Requests related to ${name.toLowerCase()}`,
//   createdAt: new Date().toISOString(),
// }));

export function getCategoryId(name: string): string | undefined {
  return CATEGORY_IDS[name as keyof typeof CATEGORY_IDS];
}

export function getCategoryById(id: string): string | undefined {
  const entry = Object.entries(CATEGORY_IDS).find(([_, value]) => value === id);
  return entry ? entry[0] : undefined;
}

export function categorizeText(text: string): string {
  const lowercaseText = text.toLowerCase();
  
  // First check for AI keywords as they take priority
  const aiKeywords = ['ai', 'artificial intelligence', 'victoria', 'chat', 'assistant'];
  if (aiKeywords.some(keyword => lowercaseText.includes(keyword))) {
    return CATEGORIES.AI;
  }
  
  const categoryKeywords = {
    [CATEGORIES.BROKER]: ['commission', 'broker', 'agent', 'listing', 'sale', 'contract', 'offer'],
    [CATEGORIES.TECH]: ['website', 'login', 'password', 'system', 'email', 'software', 'access'],
    [CATEGORIES.ACCOUNTING]: ['payment', 'invoice', 'tax', 'expense', 'receipt', 'accounting', 'finance'],
    [CATEGORIES.MARKETING]: ['marketing', 'social media', 'photo', 'video', 'advertisement', 'promote'],
    [CATEGORIES.PAPERWORK]: ['form', 'document', 'paperwork', 'sign', 'agreement', 'file'],
  };
  
  for (const [category, keywords] of Object.entries(categoryKeywords)) {
    if (keywords.some(keyword => lowercaseText.includes(keyword))) {
      return category;
    }
  }
  
  return CATEGORIES.UNCATEGORIZED;
}

export function generateTitle(text: string): string {
  const firstSentence = text.split(/[.!?]/)[0].trim();
  if (firstSentence.length <= 50) return firstSentence;
  return firstSentence.substring(0, 47) + '...';
}

export function validateCategory(category: any): category is Category {
  return (
    category &&
    typeof category === 'object' &&
    typeof category.id === 'string' &&
    typeof category.name === 'string' &&
    typeof category.description === 'string' &&
    typeof category.createdAt === 'string'
  );
}