import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "@/context/AuthContext";

interface Props {
  children: ReactNode;
}

const owners = ["tyler@joinvictory.com", "aqilusman49@gmail.com"];
export const isOwner = (email: string) => owners?.includes(email);

const OwnerRequired = ({ children }: Props) => {
  const { state } = useAuth();
  const user = state?.user;

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!isOwner(user?.email)) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

export default OwnerRequired;
