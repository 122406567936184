import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import UserDashboard from './dashboards/UserDashboard';
import AdminDashboard from './dashboards/AdminDashboard';

export default function Dashboard() {
  const navigate = useNavigate();
  const { state: { user } } = useAuth();

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
  }, [user, navigate]);

  if (!user) return null;

  console.log({user});
  
  
  // Strict role-based dashboard rendering
  switch (user.role) {
    case 'AGENT':
      return <UserDashboard />;
    case 'ADMIN':
    case 'SUPER_ADMIN':
      return <AdminDashboard />;
    default:
      // Log unexpected role and redirect to login
      console.error('Invalid user role:', user.role);
      navigate('/login');
      return null;
  }
}