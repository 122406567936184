import { User, Request } from './types';

export function canManageUsers(user: User): boolean {
  return ['SUPER_ADMIN', 'ADMIN'].includes(user.role);
}

export function canManageOffices(user: User): boolean {
  return user.role === 'SUPER_ADMIN';
}

export function canManageCategories(user: User): boolean {
  return user.role === 'SUPER_ADMIN';
}

export function canManageAdmins(user: User): boolean {
  return user.role === 'SUPER_ADMIN';
}

export function canAccessAdminRoutes(user: User): boolean {
  return ['SUPER_ADMIN', 'ADMIN'].includes(user.role);
}

export function canAccessSuperAdminRoutes(user: User): boolean {
  return user.role === 'SUPER_ADMIN';
}

export function canProcessRequest(user: User, request: Request): boolean {
  if (user.role === 'SUPER_ADMIN') return true;
  if (user.role === 'ADMIN') return true;
  
  // const hasOfficeAccess = user.officeIds.includes(request.officeId);
  
  // return hasOfficeAccess;
  return true
}

export function canReassignRequest(user: User, request: Request): boolean {
  return user.role === 'SUPER_ADMIN' || 
         (user.role === 'ADMIN' && request.adminId === user.id);
}

export function canCreateRequest(user: User): boolean {
  return user.role === 'AGENT';
}

export function getAdminsByCategory(admins: User[], categoryId: string, officeId: string): User[] {
  // First, get super admins as they can handle all requests
  const superAdmins = admins.filter(admin => admin.role === 'SUPER_ADMIN');
  
  // Then get regular admins who can handle this category and office
  const regularAdmins = admins.filter(admin => 
    admin.role === 'ADMIN' &&
    admin.officeIds.includes(officeId) &&
    admin.categoryIds.includes(categoryId)
  );

  // Combine and sort by role (super admins first)
  return [...superAdmins, ...regularAdmins];
}




export function findEligibleAdmin(admins: User[], request: Request): User | null {
  // // If it's an AI request, no admin needed
  // if (request.category === 'AI Assistant') {
  //   return null;
  // }

  const eligibleAdmins:any = admins.filter((admin) =>
    admin.role === "ADMIN" &&
    admin.officeIds.includes(request.officeId as string) &&
    admin.categoryIds.includes(request.categoryId)
  );
  
  if (eligibleAdmins) {
    return eligibleAdmins?.length > 1 ? null : eligibleAdmins[0];
  }
  const superAdmin = admins.find(admin => 
    admin.role === 'SUPER_ADMIN' &&
    admin.email === 'tyler@joinvictory.com'
  );

  return superAdmin || null;
}