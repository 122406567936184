import { useAdmin } from "@/context/AdminContext";
import { Data, useBulkUpload } from "@/context/BulkUploadContext";
import clsx from "clsx";
import Button from "../ui/Button";
import { useNavigate } from "react-router-dom";

export default function BulkUploadReport() {
  const { dispatch, state } = useBulkUpload();
  const navigate = useNavigate();

  console.log({ state });
  return (
    <div>
      <div className="mb-6">
        <h1 className="text-2xl font-semibold text-gray-900">
          Creation Report
        </h1>
      </div>
      <div className={clsx("overflow-x-auto")}>
        <div className="inline-block  align-middle min-w-[800px] md:min-w-[1024px] w-full">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <div className="grid grid-cols-12 min-w-full border-b border-gray-300 bg-gray-50">
              <div className="col-span-4 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Email
              </div>
              <div className="col-span-4 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Status
              </div>
              <div className="col-span-4 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Error
              </div>
            </div>

            {state?.report?.map((node) => {
              console.log(node?.message);

              return (
                <div
                  key={node?.email}
                  className="grid grid-cols-12 min-w-full border-b border-gray-200 bg-white"
                >
                  <div className="col-span-4 whitespace-wrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {node?.email}
                  </div>
                  <div className="col-span-4 whitespace-wrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {node?.success ? (
                      <div className="text-green-600 border border-green-600 w-fit px-2 py-1 rounded-lg  font-medium text-base">
                        Success
                      </div>
                    ) : (
                      <div className="text-red-600 border border-red-600 w-fit px-2 py-1 rounded-lg font-medium text-base">
                        Failed
                      </div>
                    )}
                  </div>
                  <div className="col-span-4 whitespace-wrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {node?.message ? node?.message : "None"}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="w-full flex items-center mt-4 justify-end">
        <Button
          onClick={() => {
            dispatch({ type: "UNSET_REPORT" });
            navigate("/dashboard/admin/users");
          }}
          className="w-full sm:ml-3 sm:w-auto"
        >
          Done
        </Button>
      </div>
    </div>
  );
}
