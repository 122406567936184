import { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuth } from "@/context/AuthContext";
import Button from "@/components/ui/Button";
import { resetPassword } from "@/lib/auth";
import { ArrowUpRightIcon, LogOut } from "lucide-react";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const { login, state } = useAuth();
  const emailParams = location?.state?.email;
  const [formData, setFormData] = useState({
    email: emailParams,
  });
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);


  const handleResetPassword = async (e: any) => {
    e.preventDefault();
    if (!formData.email) {
      setError("Please enter your email address");
      return;
    }

    setError(null);
    setSuccess(null);
    setIsSubmitting(true);

    try {
      await resetPassword(formData?.email);
      setSuccess(
        "Password reset email has been sent. Please check your inbox."
      );
    } catch (err: any) {
      setError(err.message || "Failed to send password reset email.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-[#0A0B1A] min-h-screen flex flex-col pt-6 pb-6 px-4 sm:px-6 md:pt-6 lg:px-8 ">
      <Link to="/" className="text-white w-fit ml-auto">
        <Button
          variant="outline"
          size="sm"
          onClick={() => null}
          className="text-white"
        >
          <LogOut className="h-4 w-4" />
        </Button>
      </Link>
      <div className="min-h-full flex items-center justify-center grow mt-2">
        <div className="max-w-md w-full space-y-8">
          <div>
            <div>
              <img
                width={400}
                className="mx-auto mb-4"
                src="/images/victoria.png"
                alt="logo"
              />
            </div>
            <h1 className="text-center text-4xl font-bold tracking-tight">
              <span className="bg-gradient-to-r from-[#4169E1] to-[#9370DB] text-transparent bg-clip-text">
              Reset Password
              </span>
            </h1>
          </div>

          <form className="mt-8 space-y-4" onSubmit={handleResetPassword}>
            {(error || state?.error) && (
              <div className="rounded-md bg-red-50 p-4">
                <p className="text-sm text-red-700">{error || state?.error}</p>
              </div>
            )}

            {success && (
              <div className="rounded-md bg-green-50 p-4">
                <p className="text-sm text-green-700">{success}</p>
              </div>
            )}

            <div className="rounded-md shadow-sm -space-y-px">
              <div className="mb-4">
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <input
                  id="email"
                  type="email"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  className="appearance-none relative block w-full px-3 py-2 border border-gray-700 bg-gray-800 text-white placeholder-gray-400 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  required
                />
              </div>
            </div>
            <div className="flex items-center justify-between !my-6">
              <div className="text-sm">
                <button
                  type="button"
                  onClick={() => navigate("/login")}
                  className="text-blue-400 hover:text-blue-300 flex items-center gap-1"
                >
                  Sign in
                  <ArrowUpRightIcon className="h-4 w-4" />
                </button>
              </div>
            </div>
            <div>
              <Button
                type="submit"
                className="w-full flex justify-center py-2 px-4"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
