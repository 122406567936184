import { useEffect, useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "@/lib/firebase/app";
import { User } from "@/lib/types";
import { Users } from "lucide-react";
import Button from "@/components/ui/Button";
import Modal from "./ui/Modal";
import { useAuth } from "@/context/AuthContext";
import ButtonLoader from "./ui/ButtonLoader";
import { generateApprovalEmailFunction } from "@/email template/generateApprovalEmail";

interface SuperAdminTableProps {
  superAdmins: User[];
  id?: string;
}

export default function SuperAdminTable({
  superAdmins,
  id,
}: SuperAdminTableProps) {
  const { getToken } = useAuth();

  const [isUpdating, setIsUpdating] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSuperAdminId, setSelectedSuperAdminId] = useState<
    string | null
  >(null);
  const [selectedSuperAdminData, setSelectedSuperAdminData] = useState<any>({});
  const [actionType, setActionType] = useState<"approve" | "disapprove" | null>(
    null
  );

  const sortedSuperAdmins = [...superAdmins].sort((a, b) => {
    if (a.accountStatus === "PENDING" && b.accountStatus !== "PENDING")
      return -1;
    if (a.accountStatus !== "PENDING" && b.accountStatus === "PENDING")
      return 1;
    return 0;
  });

  if (sortedSuperAdmins.length === 0) {
    return (
      <div className="rounded-lg bg-white p-8 text-center">
        <Users className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-semibold text-gray-900">
          No Super Admins
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          No Super Admin accounts have been created yet.
        </p>
      </div>
    );
  }

  const handleSuperAdminStatus = async (
    superAdminId: string,
    status: "ACTIVE" | "PENDING"
  ) => {
    try {
      setIsUpdating(true);
      const userRef = doc(db, "users", superAdminId);
      await updateDoc(userRef, { accountStatus: status });
      const token = (await getToken()) as string;
      if (status === "ACTIVE") {
        await generateApprovalEmailFunction({
          name: selectedSuperAdminData["name"] || "",
          email: selectedSuperAdminData["email"],
        });
      }
      console.log(
        `Super admin ${
          status === "ACTIVE" ? "approved" : "unapproved"
        } successfully`
      );
      setIsModalOpen(false);
    } catch (error) {
      console.error(
        `Error occurred while ${
          status === "ACTIVE" ? "approving" : "unapproving"
        } super admin`,
        error
      );
    } finally {
      setIsUpdating(false);
      setSelectedSuperAdminId(null);
      setSelectedSuperAdminData({});
    }
  };

  return (
    <div className="mt-8 flow-root">
      <div className="-my-2 overflow-x-auto">
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Name
                  </th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Email
                  </th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  <th className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {sortedSuperAdmins?.map((superAdmin) => (
                  <tr
                    id={superAdmin?.id}
                    key={superAdmin.id}
                    className={id === superAdmin.id ? "!bg-gray-200/70" : ""}
                  >
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {superAdmin.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {superAdmin.email}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {superAdmin.accountStatus}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <Button
                        onClick={() => {
                          setSelectedSuperAdminId(superAdmin.id);
                          setSelectedSuperAdminData({
                            name: superAdmin.name,
                            email: superAdmin.email,
                          });
                          setActionType(
                            superAdmin.accountStatus === "ACTIVE"
                              ? "disapprove"
                              : "approve"
                          );
                          setIsModalOpen(true);
                        }}
                        className={
                          superAdmin.accountStatus === "PENDING"
                            ? "bg-blue-600"
                            : "bg-black/60"
                        }
                        disabled={isUpdating}
                        aria-busy={isUpdating}
                      >
                        {superAdmin.accountStatus === "ACTIVE"
                          ? "Disapprove"
                          : "Approve"}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isModalOpen && actionType && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={`${actionType === "approve" ? "Approve" : "Disapprove"} User`}
        >
          <p>
            Are you sure you want to{" "}
            <span className="font-semibold">
              {actionType === "approve" ? "approve" : "disapprove"}
            </span>{" "}
            this user?
          </p>
          <div className="flex items-center justify-end gap-3 mt-4">
            <Button disabled={isUpdating} onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button
              disabled={isUpdating}
              aria-busy={isUpdating}
              onClick={() => {
                if (selectedSuperAdminId) {
                  handleSuperAdminStatus(
                    selectedSuperAdminId,
                    actionType === "approve" ? "ACTIVE" : "PENDING"
                  );
                }
              }}
            >
              Confirm
              {isUpdating && <ButtonLoader />}
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
}
