import { Data, useBulkUpload } from "@/context/BulkUploadContext";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import FileInput from "../FileInput";
import BulkUploadInstructions from "../BulkUploadInstructions";
import { useAdmin } from "@/context/AdminContext";
import { options } from "./BulkUploadStep2";

export default function BulkUploadStep1() {
  const { dispatch, state } = useBulkUpload();
  const { state: adminState } = useAdmin();
  console.log(state);

  const normalizeHeader = (header: string) => {
    const normalized = header.toLowerCase().trim();
    console.log({ normalized });

    // Return normalized version of the header, handle various cases
    if (
      normalized === "email" ||
      normalized === "EMAIL" ||
      normalized === "Email"
    )
      return "email";
    if (normalized === "name" || normalized === "NAME" || normalized === "Name")
      return "name";
    if (normalized === "role" || normalized === "ROLE" || normalized === "Role")
      return "role";
    if (
      normalized === "offices" ||
      normalized === "OFFICES" ||
      normalized === "Offices"
    )
      return "offices";
    if (
      normalized === "categories" ||
      normalized === "CATEGORIES" ||
      normalized === "Categories"
    )
      return "categories";
    if (
      normalized === "phoneNumber" ||
      normalized === "phone number" ||
      normalized === "PhoneNumber" ||
      normalized === "PHONENUMBER" ||
      normalized === "phonenumber" ||
      normalized === "Phone Number" ||
      normalized === "Phone number"
    )
      return "phoneNumber";
  };

  const handleFileChange = (file: File | null) => {
    if (!file) {
      return;
    }
    const fileExtension = file.name.split(".").pop()?.toLowerCase();

    if (fileExtension === "csv") {
      Papa.parse(file, {
        complete: (result) => {
          console.log("Parsed CSV:", result);

          const dataToSend = result?.data
            ?.map((node: any) => {
              // Normalize keys
              const normalizedNode: any = {};
              Object.keys(node).forEach((key) => {
                const normalizedKey = normalizeHeader(key) as string;
                normalizedNode[normalizedKey] = node[key];
              });
              console.log({ normalizedNode });

              const offices = normalizedNode?.offices
                ?.split(",")
                ?.filter((node: string) => node);
              const roleToSend = normalizedNode?.role
                ?.split(",")
                ?.filter((node: string) => node);
              const categories = normalizedNode?.categories
                ?.split(",")
                ?.filter((node: string) => node);

              let role = null;
              for (let i = 0; i < roleToSend.length; i++) {
                const currentRole = roleToSend[i]?.toLowerCase()?.trim();
                if (
                  options.some(
                    (option) =>
                      option?.value?.toLowerCase()?.trim() === currentRole
                  )
                ) {
                  role = currentRole;
                  break;
                }
              }
              const validRole = role ? role : null;

              let officeIds = [];
              if (offices?.length > 0) {
                for (let i = 0; i < offices.length; i++) {
                  const currentOffice = offices[i].toLowerCase().trim();

                  const matchedOffice = adminState?.offices?.find(
                    (office: any) =>
                      office.name?.toLowerCase().trim() === currentOffice
                  );

                  if (matchedOffice) {
                    officeIds.push(matchedOffice.id);
                    if (validRole === "AGENT") {
                      break;
                    }
                  }
                }
              }

              const categoriesIds = categories
                ?.map((node: string) => {
                  return adminState?.categories?.find(
                    (category: any) =>
                      category?.name?.toLowerCase().trim() ===
                      node?.toLowerCase()?.trim()
                  )?.id;
                })
                ?.filter((id: any) => id !== undefined && id !== null);
              delete normalizedNode["offices"];
              delete normalizedNode["categories"];

              return {
                ...normalizedNode,
                role: validRole?.toUpperCase(),
                phoneNumber:
                  (normalizedNode?.phoneNumber?.startsWith("+")
                    ? normalizedNode?.phoneNumber
                    : `+${normalizedNode?.phoneNumber}`) || "",
                officeIds:
                  officeIds !== undefined || officeIds !== null
                    ? validRole?.toUpperCase() === "AGENT"
                      ? [officeIds[0]]?.filter(
                          (id: any) => id !== undefined && id !== null
                        )
                      : [
                          ...new Set(
                            officeIds?.filter(
                              (id: any) => id !== undefined && id !== null
                            )
                          ),
                        ]
                    : [],
                categoryIds:
                  categoriesIds !== undefined || categoriesIds !== null
                    ? validRole?.toUpperCase() === "AGENT"
                      ? []
                      : [
                          ...new Set(
                            categoriesIds?.filter(
                              (id: any) => id !== undefined && id !== null
                            )
                          ),
                        ]
                    : [],
                id: crypto.randomUUID(),
              };
            })
            ?.filter(
              (node) =>
                node?.name !== "" && node?.email !== "" && node?.role !== ""
            );
          console.log({ dataToSend });

          dispatch({
            type: "SET_DATA",
            payload: { data: dataToSend as Data[], fileName: file?.name },
          });
        },
        header: true,
      });
    } else if (fileExtension === "xlsx") {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        console.log("Parsed XLSX:", jsonData);

        const dataToSend = jsonData?.map((node: any) => {
          // Normalize keys
          const normalizedNode: any = {};
          Object.keys(node).forEach((key) => {
            const normalizedKey = normalizeHeader(key) as string;
            normalizedNode[normalizedKey] = node[key];
          });
          console.log({ normalizedNode });

          const offices = normalizedNode?.offices
            ?.split(",")
            ?.filter((node: string) => node);
          const roleToSend = normalizedNode?.role
            ?.split(",")
            ?.filter((node: string) => node);
          const categories = normalizedNode?.categories
            ?.split(",")
            ?.filter((node: string) => node);

          let role = null;
          for (let i = 0; i < roleToSend.length; i++) {
            const currentRole = roleToSend[i]?.toLowerCase()?.trim();
            if (
              options.some(
                (option) => option?.value?.toLowerCase()?.trim() === currentRole
              )
            ) {
              role = currentRole;
              break;
            }
          }
          const validRole = role ? role : null;

          let officeIds = [];
          if (offices?.length > 0) {
            for (let i = 0; i < offices.length; i++) {
              const currentOffice = offices[i].toLowerCase().trim();
              const matchedOffice = adminState?.offices?.find(
                (office: any) =>
                  office.name?.toLowerCase().trim() === currentOffice
              );
              if (matchedOffice) {
                officeIds.push(matchedOffice.id);
                if (validRole === "AGENT") {
                  break;
                }
              }
            }
          }

          const categoriesIds = categories
            ?.map((node: string) => {
              return adminState?.categories?.find(
                (category: any) =>
                  category?.name?.toLowerCase().trim() ===
                  node?.toLowerCase()?.trim()
              )?.id;
            })
            ?.filter((id: any) => id !== undefined && id !== null);
          delete normalizedNode["offices"];
          delete normalizedNode["categories"];

          return {
            ...normalizedNode,
            role: validRole?.toUpperCase(),
            phoneNumber:
              (String(normalizedNode?.phoneNumber).startsWith("+")
                ? String(normalizedNode?.phoneNumber)
                : `+${normalizedNode?.phoneNumber}`) || "",
            officeIds:
              officeIds !== undefined || officeIds !== null
                ? validRole?.toUpperCase() === "AGENT"
                  ? [officeIds[0]]?.filter(
                      (id: any) => id !== undefined && id !== null
                    )
                  : [
                      ...new Set(
                        officeIds?.filter(
                          (id: any) => id !== undefined && id !== null
                        )
                      ),
                    ]
                : [],
            categoryIds:
              categoriesIds !== undefined || categoriesIds !== null
                ? validRole?.toUpperCase() === "AGENT"
                  ? []
                  : [
                      ...new Set(
                        categoriesIds?.filter(
                          (id: any) => id !== undefined && id !== null
                        )
                      ),
                    ]
                : [],
            id: crypto.randomUUID(),
          };
        });
        console.log({ dataToSend });

        dispatch({
          type: "SET_DATA",
          payload: { data: dataToSend as Data[], fileName: file?.name },
        });
      };
      reader.readAsBinaryString(file);
    } else {
      alert("Unsupported file type. Please upload CSV or XLSX.");
    }
  };
  const handleClear = () => {
    dispatch({
      type: "CLEAR",
    });
  };
  return (
    <div>
      <BulkUploadInstructions />
      <div className="flex items-center justify-between mb-4">
        <div className="w-full mt-4 flex flex-col justify-center items-center gap-4">
          <div className="w-full">
            <label
              htmlFor="file"
              className="block text-sm mb-2 font-medium text-gray-700"
            >
              Attach file
            </label>
            <FileInput
             warning={state?.error ? true : false}
              errorMessage={state?.error as string}
              onClose={handleClear}
              heading="File"
              fileNameToDisplay={state?.fileName}
              inputName="File"
              onChange={handleFileChange}
              accept={{
                "text/csv": [".csv"],
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                  [".xlsx"],
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
