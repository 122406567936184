import { useCallback } from "react";
import clsx from "clsx";
import { Accept, useDropzone } from "react-dropzone";
import { FileWarning } from "lucide-react";

interface FileInputProps {
  otherClasses?: string;
  heading: string;
  onChange: (file: File | null) => void;
  fileNameToDisplay?: string;
  warning?: boolean;
  accept?: Accept;
  inputName: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onClose: () => void;
  errorMessage?: string;
}

export const FileInput: React.FC<FileInputProps> = ({
  otherClasses,
  heading,
  onChange,
  fileNameToDisplay,
  warning,
  accept,
  inputName,
  onBlur,
  onClose,
  errorMessage
}) => {
  let name = "";
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      console.log("click");

      onChange(acceptedFiles[0] || null);
    },
    [onChange]
  );

  const { getRootProps, getInputProps ,inputRef } = useDropzone({
    onDrop,
    noClick: true,
    accept,
  });

  const fileInputClasses = clsx(
    otherClasses,
    " cursor-pointer w-full rounded-[4px] border-[1px] border-[#B2B2B2] border-dashed",
    warning ? "border-[#ff4444]" : "border-[#B2B2B2]"
  );

  return (
    <div className={fileInputClasses}>
      <label
        {...getRootProps()}
        className="flex cursor-pointer flex-col items-center px-3 py-10"
      >
        <div className="flex w-full cursor-pointer flex-col items-center gap-3">
          {warning ? (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.6654 5.33317H7.9987C7.29145 5.33317 6.61318 5.61412 6.11308 6.11422C5.61298 6.61432 5.33203 7.29259 5.33203 7.99984V21.3332M5.33203 21.3332V23.9998C5.33203 24.7071 5.61298 25.3854 6.11308 25.8855C6.61318 26.3856 7.29145 26.6665 7.9987 26.6665H23.9987C24.7059 26.6665 25.3842 26.3856 25.8843 25.8855C26.3844 25.3854 26.6654 24.7071 26.6654 23.9998V18.6665M5.33203 21.3332L11.4467 15.2185C11.9468 14.7186 12.6249 14.4377 13.332 14.4377C14.0391 14.4377 14.7173 14.7186 15.2174 15.2185L18.6654 18.6665M26.6654 18.6665V13.3332M26.6654 18.6665L24.5507 16.5518C24.0506 16.0519 23.3725 15.7711 22.6654 15.7711C21.9583 15.7711 21.2801 16.0519 20.78 16.5518L18.6654 18.6665M18.6654 18.6665L21.332 21.3332M23.9987 5.33317H29.332M26.6654 2.6665V7.99984M18.6654 10.6665H18.6787"
                stroke="#FF4444"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.6654 5.33317H7.9987C7.29145 5.33317 6.61318 5.61412 6.11308 6.11422C5.61298 6.61432 5.33203 7.29259 5.33203 7.99984V21.3332M5.33203 21.3332V23.9998C5.33203 24.7071 5.61298 25.3854 6.11308 25.8855C6.61318 26.3856 7.29145 26.6665 7.9987 26.6665H23.9987C24.7059 26.6665 25.3842 26.3856 25.8843 25.8855C26.3844 25.3854 26.6654 24.7071 26.6654 23.9998V18.6665M5.33203 21.3332L11.4467 15.2185C11.9468 14.7186 12.6249 14.4377 13.332 14.4377C14.0391 14.4377 14.7173 14.7186 15.2174 15.2185L18.6654 18.6665M26.6654 13.3332V18.6665M26.6654 18.6665L24.5507 16.5518C24.0506 16.0519 23.3725 15.7711 22.6654 15.7711C21.9583 15.7711 21.2801 16.0519 20.78 16.5518L18.6654 18.6665M18.6654 18.6665L21.332 21.3332M23.9987 5.33317H29.332M26.6654 2.6665V7.99984M18.6654 10.6665H18.6787"
                stroke="#006CEA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}

          <div className="flex w-full cursor-pointer flex-col items-center gap-1 justify-center">
            <p
              className={clsx(
                "font-Mulish text-sm font-bold leading-5 ",
                warning ? "text-[#ff4444]" : "text-[#1A1A1A]"
              )}
            >
              {heading}
            </p>
          </div>
        </div>
        {fileNameToDisplay ? (
          <>
          <div className="flex gap-2 items-center justify-center group">
            <p className="text-gray-400 font-Mulish w-full overflow-hidden text-ellipsis text-sm font-normal leading-5 text-center">
              {fileNameToDisplay}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                onClose();
                if (document.getElementById('file')) {
                  let fileInput = document.getElementById('file') as any;
                  fileInput!.value = '';
                }
              }}
              type="button"
              className=" relative z-10 flex items-center justify-center bg-blue-600 text-white group-hover:bg-blue-700 border border-gray-700 min-w-6 font-medium text-sm  w-6 h-6 rounded-full"
            >
              X
            </button>
          </div>
          {errorMessage && (
            <div className="flex text-sm mt-4 items-center gap-2 text-red-500">
              <FileWarning className="h-5 w-5" />
              <p>{errorMessage}</p>
            </div>
          )}
          </>
        ) : (
          <p
            className={clsx(
              "font-Mulish text-sm font-normal leading-5 text-center ",
              warning ? "text-[#ff4444]" : "text-gray-400"
            )}
          >
            Drag & drop an XLXS or CSV attachment or{" "}
            <span
              className={clsx(
                " font-Mulish mr-1 cursor-pointer text-sm font-normal  leading-5 underline hover:no-underline",
                warning ? "text-[#ff4444]" : "text-[#006CEA]"
              )}
            >
              upload
            </span>
            from your files
          </p>
        )}
        <input
          id="file"
          name={inputName}
          type="file"
          onBlur={onBlur}
          className="absolute opacity-0"
          hidden
          {...getInputProps()}
        />
      </label>
    </div>
  );
};

export default FileInput;
