import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  ReactNode,
} from "react";
import { Request, RequestComment, User } from "@/lib/types";
import { db } from "@/lib/firebase/app";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  doc,
  updateDoc,
  arrayUnion,
  or,
  and,
  limit,
  startAfter,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { useAuth } from "./AuthContext";
import { retryOperation } from "@/lib/firebase/utils";
import * as JsSearch from "js-search";

interface RequestsState {
  requests: Request[];
  suggestedRequests: Request[];
  // assignedRequest: Request[];
  loading: boolean;
  loadedSuggestion: boolean;
  error: string | null;
  lastVisible: any; // Used for pagination
  hasMore: boolean;
}

type RequestsAction =
  | {
      type: "SET_REQUESTS";
      payload: { requests: Request[]; lastVisible: any; hasMore: boolean };
    }
  | {
      type: "LOAD_MORE_REQUESTS";
      payload: { requests: Request[]; lastVisible: any; hasMore: boolean };
    }
  // | { type: "SET_ASSIGNED_REQUESTS"; payload: Request[] }
  | {
      type: "SEARCH";
      payload: {
        value: string;
      };
    }
  | { type: "REMOVE_SEARCH" }
  | { type: "ADD_REQUEST"; payload: Request }
  | { type: "UPDATE_REQUEST"; payload: Request }
  | { type: "DELETE_REQUEST"; payload: string }
  | {
      type: "ADD_COMMENT";
      payload: { requestId: string; comment: RequestComment };
    }
  | {
      type: "RELEASE_NOTE";
      payload: { requestId: string; comment: RequestComment; user: User };
    }
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_ERROR"; payload: string | null }
  | { type: "ASSIGN_ADMIN"; payload: { requestId: string; adminId: string } };
interface RequestsContextType {
  state: RequestsState;
  dispatch: React.Dispatch<RequestsAction>;
  loadMoreRequests: () => Promise<void>;
  getRequest: (requestId: any) => Promise<void>;
}

const RequestsContext = createContext<RequestsContextType | null>(null);

const initialState: RequestsState = {
  requests: [],
  suggestedRequests: [],
  // assignedRequest : [],
  loading: false,
  error: null,
  loadedSuggestion: false,
  lastVisible: null,
  hasMore: true,
};

function requestsReducer(
  state: RequestsState,
  action: RequestsAction
): RequestsState {
  var search = new JsSearch.Search("id");
  search.addIndex("title");
  search.addIndex('transcript');
  search.addIndex('createdBy');
  search.addDocuments(state.requests);
  switch (action.type) {
    case "SET_REQUESTS":
      return {
        ...state,
        requests: action.payload?.requests,
        lastVisible: action.payload?.lastVisible,
        hasMore: action.payload?.hasMore,
        loading: false,
        error: null,
      };

    case "LOAD_MORE_REQUESTS":
      return {
        ...state,
        requests: [...state.requests, ...action.payload.requests],
        lastVisible: action.payload.lastVisible,
        hasMore: action.payload.hasMore,
        loading: false,
        error: null,
      };

    // case "SET_ASSIGNED_REQUESTS" :
    //   return {
    //     ...state,
    //     assignedRequest: action.payload,
    //     loading: false,
    //     error: null,
    //   }

    case "SEARCH":
      const suggestion = search.search(action?.payload?.value);
      return {
        ...state,
        suggestedRequests: suggestion as any,
        loadedSuggestion: true,
      };
    case "REMOVE_SEARCH":
      return {
        ...state,
        suggestedRequests: [],
        loadedSuggestion: false,
      };

    case "ADD_REQUEST":
      return {
        ...state,
        requests: [action.payload, ...state.requests],
        error: null,
      };

    case "UPDATE_REQUEST": {
      const updatedRequests = state.requests.map((request) =>
        request.id === action.payload.id ? action.payload : request
      );
      return {
        ...state,
        requests: updatedRequests,
        error: null,
      };
    }

    case "DELETE_REQUEST":
      return {
        ...state,
        requests: state.requests.filter(
          (request) => request.id !== action.payload
        ),
        suggestedRequests: state.suggestedRequests.filter(
          (request) => request.id !== action.payload
        ),
        error: null,
      };

    case "ADD_COMMENT": {
      const { requestId, comment } = action.payload;
      const sanitizedComment = Object.fromEntries(
        Object.entries(comment).filter(([_, value]) => value !== undefined)
      );
      const updatedRequests = state.requests.map((request) => {
        if (request.id === requestId) {
          return {
            ...request,
            comments: [...(request.comments || []), comment],
            updatedAt: new Date().toISOString(),
          };
        }
        return request;
      });

      const updateFirestore = async () => {
        try {
          const requestRef = doc(db, "requests", requestId);
          await updateDoc(requestRef, {
            comments: arrayUnion(sanitizedComment),
          });
        } catch (error) {
          console.error("Failed to update Firestore:", error);
        }
      };

      updateFirestore();

      return {
        ...state,
        requests: updatedRequests,
        error: null,
      };
    }

    case "RELEASE_NOTE": {
      const { requestId, comment } = action.payload;
      const sanitizedComment = Object.fromEntries(
        Object.entries(comment).filter(([_, value]) => value !== undefined)
      );
      const updatedRequests = state.requests.map((request) => {
        if (request.id === requestId) {
          return {
            ...request,
            comments: [...(request.comments || []), comment],
            updatedAt: new Date().toISOString(),
            originalAnalysis: {
              title: request?.originalAnalysis?.title || "",
              category: request?.originalAnalysis?.category || "",
              aiResponse: "",
            },
          };
        }
        return request;
      });

      const updateFirestore = async () => {
        try {
          const requestRef = doc(db, "requests", requestId);
          await updateDoc(requestRef, {
            comments: arrayUnion(sanitizedComment),
            "originalAnalysis.aiResponse": "",
          });
        } catch (error) {
          console.error("Failed to update Firestore:", error);
        }
      };

      updateFirestore();
      return {
        ...state,
        requests: updatedRequests,
        error: null,
      };
    }

    case "ASSIGN_ADMIN": {
      const { requestId, adminId } = action.payload;

      const updatedRequests = state.requests.map((request) => {
        if (request.id === requestId) {
          return {
            ...request,
            [adminId]: adminId,
          };
        }
        return request;
      });

      // const updateFirestore = async () => {
      //   try {
      //     const requestRef = doc(db, "requests", requestId);
      //     await updateDoc(requestRef, {
      //       adminId: adminId,
      //     });
      //   } catch (error) {
      //     console.error("Failed to update Firestore:", error);
      //   }
      // };

      // updateFirestore();

      return {
        ...state,
        requests: updatedRequests,
        error: null,
      };
    }

    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
        error: null,
      };

    case "SET_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

export const updateRequest = async (request: any) => {
  try {
    const requestRef = doc(db, "requests", request.id);
    await updateDoc(requestRef, {
      ...request,
      updatedAt: new Date().toISOString(),
    });
    console.log("Request updated successfully in Firestore");
  } catch (error) {
    console.error("Error updating request in Firestore:", error);
    throw error;
  }
};

const populateAgentNamesInRequests = async (id: string) => {
  try {
    const agentQuery = query(collection(db, "users"), where("id", "==", id));
    const agentSnapshot = await getDocs(agentQuery);

    if (!agentSnapshot.empty) {
      const agentDoc = agentSnapshot.docs[0].data();
      return agentDoc.name;
    }
  } catch (error) {
    console.error("Error fetching agent's name:", error);
  }
};

export function RequestsProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(requestsReducer, initialState);
  const { state: authState } = useAuth();

  useEffect(() => {
    let unsubscribe: any = () => {};

    const setupRequestsListener = async () => {
      // Only proceed if user is authenticated
      if (!authState.isAuthenticated || !authState.user) {
        dispatch({
          type: "SET_REQUESTS",
          payload: { requests: [], lastVisible: null, hasMore: false },
        });
        return;
      }

      try {
        dispatch({ type: "SET_LOADING", payload: true });

        const setupListener = async () => {
          // Create query based on user role
          const requestsRef = collection(db, "requests");
          let requestsQuery = query(
            requestsRef,
            orderBy("createdAt", "desc"),
            limit(15)
          );
          if (
            authState?.user !== null &&
            authState?.user.role === "SUPER_ADMIN"
          ) {
            requestsQuery = query(
              requestsRef,
              where("workspaceId", "==", authState.user.workspaceId),
              orderBy("createdAt", "desc"),
              limit(15)
            );
          } else if (
            authState?.user !== null &&
            authState?.user.role === "ADMIN"
          ) {
            requestsQuery = query(
              requestsRef,
              or(
                where("adminId", "==", authState?.user?.id),
                and(
                  where("adminId", "==", null),
                  where("officeId", "in", authState?.user?.officeIds),
                  where("categoryId", "in", authState?.user?.categoryIds)
                )
              ),
              orderBy("createdAt", "desc"),
              limit(15)
            );
          } else {
            requestsQuery = query(
              requestsRef,
              where("agentId", "==", authState?.user?.id),
              orderBy("createdAt", "desc"),
              limit(15)
            );
          }

          // Set up real-time listener with retry
          return retryOperation(
            () =>
              new Promise((resolve, reject) => {
                const unsubscribe = onSnapshot(
                  requestsQuery,
                  async (snapshot) => {
                    const requests = await Promise.all(
                      snapshot.docs
                        .map(
                          (doc) =>
                            ({
                              id: doc.id,
                              ...doc.data(),
                              comments: doc.data().comments || [],
                            } as Request)
                        )
                        ?.map(async (node) => {
                          const name = await populateAgentNamesInRequests(
                            node?.agentId
                          );
                          return {
                            ...node,
                            createdBy: name,
                          };
                        })
                    );
                    const lastVisible = snapshot.docs[snapshot.docs.length - 1];
                    const hasMore = snapshot.size === 15;
                    dispatch({
                      type: "SET_REQUESTS",
                      payload: {
                        requests: requests,
                        lastVisible,
                        hasMore,
                      },
                    });
                  },
                  (error) => {
                    // Only show error for authenticated users with permission issues
                    if (
                      error.code === "permission-denied" &&
                      authState.isAuthenticated
                    ) {
                      console.error("Error fetching requests:", error);
                      dispatch({
                        type: "SET_ERROR",
                        payload:
                          "You do not have permission to access these requests.",
                      });
                    }
                    reject(error);
                  }
                );
                resolve(unsubscribe);
              }),
            3,
            1000,
            "Setting up requests listener"
          );
        };

        unsubscribe = await setupListener();
      } catch (error: any) {
        // Only show errors if authenticated
        if (authState.isAuthenticated) {
          console.error("Error setting up requests listener:", error);
          dispatch({
            type: "SET_ERROR",
            payload: "Failed to load requests. Please try again later.",
          });
        }
      }
    };

    setupRequestsListener();

    // Cleanup listener on unmount or when auth state changes
    return () => unsubscribe();
  }, [authState.isAuthenticated, authState.user]);

  // Function to load more requests
  const loadMoreRequests = async () => {
    if (!state.hasMore || state.loading) return;

    const requestsRef = collection(db, "requests");
    let requestsQuery = query(requestsRef, limit(15));

    if (authState?.user?.role === "SUPER_ADMIN") {
      requestsQuery = query(
        requestsRef,
        where("workspaceId", "==", authState.user.workspaceId),
        startAfter(state.lastVisible),
        limit(15)
      );
    } else if (authState?.user?.role === "ADMIN") {
      requestsQuery = query(
        requestsRef,
        or(
          where("adminId", "==", authState.user.id),
          and(
            where("adminId", "==", null),
            where("officeId", "in", authState.user.officeIds),
            where("categoryId", "in", authState.user.categoryIds)
          )
        ),
        startAfter(state.lastVisible),
        limit(15)
      );
    } else {
      requestsQuery = query(
        requestsRef,
        where("agentId", "==", authState?.user?.id),
        startAfter(state.lastVisible),
        limit(15)
      );
    }

    try {
      const snapshot = await getDocs(requestsQuery);
      const requests = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        comments: doc.data().comments || [],
      }));

      const uniqueRequests: any = requests.filter(
        (req) => !state.requests.some((existing) => existing.id === req.id)
      );

      const lastVisible = snapshot.docs[snapshot.docs.length - 1];
      const hasMore = snapshot.size === 15;
      const sortedRequests = uniqueRequests.sort((a: any, b: any) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      });
      const addedAgentName = await Promise.all(
        sortedRequests.map(async (node: any) => {
          const name = await populateAgentNamesInRequests(node?.agentId);
          return {
            ...node,
            createdBy: name,
          };
        })
      );

      dispatch({
        type: "LOAD_MORE_REQUESTS",
        payload: { requests: addedAgentName, lastVisible, hasMore },
      });
    } catch (error) {
      console.error("Error loading more requests:", error);
      dispatch({
        type: "SET_ERROR",
        payload: "Failed to load more requests. Please try again later.",
      });
    }
  };

  const getRequest = async (requestId: any) => {
    const isExist = state.requests?.find((req) => req.id === requestId);

    if (!isExist) {
      try {
        const requestsRef = collection(db, "requests");
        const nullAdminQuery = query(
          requestsRef,
          where("adminId", "==", null),
          where("officeId", "in", authState.user?.officeIds),
          where("categoryId", "in", authState.user?.categoryIds),
          where("id", "==", requestId)
        );

        const matchAdminQuery = query(
          requestsRef,
          where("adminId", "==", authState.user?.id),
          where("id", "==", requestId)
        );

        const [nullAdminSnapshot, matchAdminSnapshot] = await Promise.all([
          getDocs(nullAdminQuery),
          getDocs(matchAdminQuery),
        ]);

        const requests: Request[] = [];

        nullAdminSnapshot.forEach((doc) => {
          requests.push({ id: doc.id, ...doc.data() } as Request);
        });

        matchAdminSnapshot.forEach((doc) => {
          requests.push({ id: doc.id, ...doc.data() } as Request);
        });

        const uniqueRequests = Array.from(
          new Map(requests.map((req) => [req.id, req])).values()
        );

        if (uniqueRequests.length > 0) {
          dispatch({
            type: "SET_REQUESTS",
            payload: {
              ...state,
              requests: [...state.requests, ...uniqueRequests],
            },
          });
        } else {
          console.log("No matching requests found");
        }
      } catch (error) {
        console.log("Error fetching request:", error);
      }
    }
  };

  return (
    <RequestsContext.Provider
      value={{ state, dispatch, loadMoreRequests, getRequest }}
    >
      {children}
    </RequestsContext.Provider>
  );
}

export function useRequests() {
  const context = useContext(RequestsContext);
  if (!context) {
    throw new Error("useRequests must be used within a RequestsProvider");
  }
  return context;
}
