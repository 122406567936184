import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  ReactNode,
} from "react";
import {  collection, doc, onSnapshot, serverTimestamp, updateDoc, writeBatch } from "firebase/firestore";
import { useAuth } from "./AuthContext";
import { getNotificationRef } from "@/lib/firebase/notification";
import { Timestamp } from "@firebase/firestore-types";
import { db } from "@/lib/firebase";

export interface Notification {
  id: string;
  userId: string;
  title: string;
  message: string;
  read: boolean;
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  approval?: boolean;
  requestId?: string;
  workspaceId?: string;
  type?: string;
  key?: string;
}

interface NotificationState {
  notifications: Notification[];
  loading: boolean;
  error: string | null;
}

type NotificationAction =
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_NOTIFICATIONS"; payload: Notification[] }
  | { type: "SET_ERROR"; payload: string | null };

const initialState: NotificationState = {
  notifications: [],
  error: null,
  loading: true,
};


interface NotificationContextType {
  state: NotificationState;
  dispatch: React.Dispatch<NotificationAction>;
  markAsRead: (id: string) => Promise<void>;
  markAsAllRead: () => Promise<void>;
}

const NotificationContext = createContext<NotificationContextType | null>(null);


function adminReducer(
  state: NotificationState,
  action: NotificationAction
): NotificationState {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, loading: action.payload, error: null };

    case "SET_ERROR":
      return { ...state, error: action.payload, loading: false };
    case "SET_NOTIFICATIONS":
      return { ...state, notifications: action.payload, loading: false };
    default:
      return state;
  }
}

export function NotificationProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(adminReducer, initialState);
  const { state: authState } = useAuth();

  useEffect(() => {
    if (!authState?.user?.id) return;
  
    const unSubNotifications = onSnapshot(
      collection(db, `users/${authState.user.id}/notifications`),
      (snapshot) => {
        const notifications: any = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch({ type: "SET_NOTIFICATIONS", payload: notifications });
      },
      (error) => {
        console.error("Error loading notifications:", error);
        dispatch({
          type: "SET_ERROR",
          payload: "Failed to load notifications",
        });
      }
    );
  
    return () => {
      unSubNotifications();
    };
  }, [authState?.user?.id, dispatch]);


  const markAsRead = async (id: string) => {
    try {
      const ref = doc(db, `users/${authState?.user?.id}/notifications`, id);
      await updateDoc(ref, {read: true,updatedAt:serverTimestamp()} as any);
      console.log("notification mark as read  successfully from Firestore");
    } catch (error: any) {
      console.error("Error notification mark as read from Firestore:", error.message);
      dispatch({
        type: "SET_ERROR",
        payload: "Error notification mark as read. Please try again later.",
      });
    }
  };



  const markAsAllRead = async () => {
    try {  
      const batch = writeBatch(db);
      const notReadedNotifications= state?.notifications?.filter((notification) => !notification.read);
  
      notReadedNotifications?.forEach((docSnap) => {
        const notificationRef = doc(db, `users/${authState?.user?.id}/notifications`, docSnap.id);
        batch.update(notificationRef, {
          read: true,
          updatedAt: serverTimestamp(),
        });
      });  
      await batch.commit();
      dispatch({
        type: "SET_NOTIFICATIONS",
        payload: state.notifications.map((n) => ({ ...n, read: true })),
      });
      console.log("All notifications marked as read successfully.");
    } catch (error: any) {
      console.error("Error marking all notifications as read:", error.message);
      dispatch({
        type: "SET_ERROR",
        payload: "Error marking notifications as read. Please try again later.",
      });
    }
  };

  return (
    <NotificationContext.Provider value={{ state, dispatch,markAsAllRead,markAsRead }}>
      {children}
    </NotificationContext.Provider>
  );
}

export function useNotifications() {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotifications must be used within a NotificationProvider"
    );
  }
  return context;
}
