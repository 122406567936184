const sendEmailendpoint =
  import.meta.env.VITE_FIREBASE_CLOUD_URL + "/sendEmail";


export const sendCustomMail = async ({
  body,
}: {
  body: Record<string, any>;
}) => {
  return await fetch(sendEmailendpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
};
