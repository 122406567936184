import { useBulkUpload } from "@/context/BulkUploadContext";
import Button from "@/components/ui/Button";
import clsx from "clsx";
import BulkUploadStep1 from "./BulkUploadSteps/BulkUploadStep1";
import BulkUploadStep2 from "./BulkUploadSteps/BulkUploadStep2";
import BulkUploadStep3 from "./BulkUploadSteps/BulkUploadStep3";
import { useAuth } from "@/context/AuthContext";
import { createBulkUsers } from "@/api/user";
import { CustomToast } from "@/utils/helpers";
import BulkUploadReport from "./BulkUploadSteps/BulkUploadReport";
import { useEffect } from "react";

export default function BulkUpload() {
  const { dispatch, state } = useBulkUpload();
  const { state: authState, getToken } = useAuth();

  const onPreview = () => {
    dispatch({ type: "SET_STEP", payload: state?.step + 1 });
  };
  const onBack = () => {
    dispatch({
      type: "SET_STEP",
      payload: state?.step > 1 ? state?.step - 1 : 1,
    });
  };

  const isButtonDisabled = () => {
    const { data } = state;

    for (const item of data) {
      console.log({ item });
      const { name, email, officeIds, categoryIds, role, phoneNumber } = item;

      if (!name || !email || !role ) {
        return true;
      }

      const phoneNumberRegex = /^\+?[1-9]\d{1,14}$/
  
      if (!phoneNumberRegex.test(phoneNumber)) {
        return true;
      }

      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(email)) {
        return true;
      }

      if (role === "AGENT") {
        if (categoryIds && categoryIds.length > 0) {
          return true;
        }
        if (!officeIds || officeIds.length === 0) {
          return true;
        }
      }

      if (role === "ADMIN") {
        if (
          !officeIds ||
          officeIds.length === 0 ||
          !categoryIds ||
          categoryIds.length === 0
        ) {
          return true;
        }
      }
    }

    return false;
  };

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      e.preventDefault();
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });
      const usersToCreate = state?.data?.map((node) => {
        return {
          email: node?.email,
          name: node?.name,
          role: node?.role,
          officeIds: node?.officeIds || [],
          categoryIds: node?.categoryIds || [],
          workspaceId: authState?.user?.workspaceId,
          phoneNumber: node?.phoneNumber,
        };
      });
      console.log({ usersToCreate });

      const token = (await getToken()) as string;
      console.log({ token });

      const res: any = await createBulkUsers({
        token,
        body: {
          users: usersToCreate,
        },
      });

      if (res.status !== 200) {
        const errorText = await res.json();
        throw new Error(errorText.message);
      }
      const resJson = await res.json();
      console.log({ resJson });

      const { successfulUsers } = resJson || {};

      dispatch({
        type: "SET_REPORT",
        payload: { report: successfulUsers, showReport: true },
      });
    } catch (error: any) {
      CustomToast("error", error?.message);
    } finally {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    }
  };

  return (
    <div className="mx-auto max-w-7xl flex flex-col gap-6">
      {state?.showReport ? (
        <BulkUploadReport />
      ) : (
        <>
          {state?.step === 1 && <BulkUploadStep1 />}
          {state?.step === 2 && <BulkUploadStep2 />}
          {state?.step === 3 && <BulkUploadStep3 />}
          <div
            className={clsx(
              state?.step > 1 ? "justify-between" : "justify-end",
              state?.step == 2 ? 'min-w-[1100px] lg:min-w-[1280px] sm:pr-0 pr-4' : '',
              "flex w-full gap-10"
            )}
          >
            {state?.step > 1 && (
              <Button
                onClick={onBack}
                variant="outline"
                className="sm:ml-3"
              >
                Back
              </Button>
            )}
            {state?.step < 3 ? (
              <Button
                onClick={onPreview}
                disabled={
                  state?.step === 1
                    ? state?.fileName === "" || state?.data.length === 0
                    : state?.step === 2
                    ? isButtonDisabled()
                    : false
                }
                type="submit"
                className="sm:ml-3"
              >
                Next
              </Button>
            ) : (
              <Button
                disabled={state?.loading}
                onClick={onSubmit}
                className="w-full sm:ml-3 sm:w-auto"
              >
                Submit
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
}
