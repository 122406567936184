import BulkUpload from "@/components/BulkUpload";
import { BulkUploadProvider } from "@/context/BulkUploadContext";

export default function BulkUserCreation() {
  return (
    <BulkUploadProvider>
        <BulkUpload />
    </BulkUploadProvider>
  );
}
