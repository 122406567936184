import { useState } from "react";
import { useAdmin } from "@/context/AdminContext";
// import { Category } from '@/lib/types';
import Button from "@/components/ui/Button";
import CategoryTable from "@/components/categories/CategoryTable";
import CategoryForm from "@/components/categories/CategoryForm";
import EmptyState from "@/components/categories/EmptyState";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "@/lib/firebase/app";
import { generateId } from "@/lib/utils";
import { useAuth } from "@/context/AuthContext";
import Modal from "@/components/ui/Modal";
import { Select, Space } from "antd";
import ButtonLoader from "@/components/ui/ButtonLoader";
import { insertNotification } from "@/lib/firebase/notification";

async function checkAssistantValidity({
  apiKey,
  assistantId,
}: {
  apiKey: string;
  assistantId?: string;
}) {
  try {
    const response = await fetch(
      `https://api.openai.com/v1/assistants/${assistantId}`,
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "OpenAI-Beta": "assistants=v2",
          "Content-Type": "application/json",
        },
      }
    );
    const resJson = await response.json();

    if (response?.ok) {
      return {
        success: true,
      };
    } else {
      return {
        success: false,
        error: resJson?.error?.message,
      };
    }
  } catch (error: any) {
    console.log(error);

    return {
      status: false,
      error: error?.message,
    };
  }
}

export default function Categories() {
  const { state, dispatch } = useAdmin();
  const { state: auth } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState({
    modal: false,
    assignCategoryModal: false,
    isEdit: false,
  });
  const [editingCategory, setEditingCategory] = useState<any | null>(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState<any>("");
  const [categoryId, setCategoryId] = useState<string | null>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getCategoryName = (categoryId: string): string => {
    const category = state?.categories?.find(
      (category: Record<string, any>) => category?.id === categoryId
    );
    return category?.name || "";
  };

  const handleSubmit = async () => {
    const category: any = {
      id: editingCategory?.id || generateId(),
      name: editingCategory.name,
      description: editingCategory.description,
      createdAt: editingCategory?.createdAt || new Date().toISOString(),
      workspaceId: auth?.user?.id,
      default: editingCategory?.default || false,
      assistantId: editingCategory?.assistantId,
    };
    setIsSubmitting(true);
    try {
      const res = await checkAssistantValidity({
        apiKey: auth?.user?.openAiKey as string,
        assistantId: editingCategory?.assistantId,
      });
      console.log(res);

      if (res?.success) {
        if (!isModalOpen?.isEdit) {
          await setDoc(doc(db, "categories", category.id), category);
        } else {
          dispatch({
            type: editingCategory ? "UPDATE_CATEGORY" : "ADD_CATEGORY",
            payload: {
              id: editingCategory.id,
              name: category.name,
              description: category.description,
              assistantId: category.assistantId,
            },
          } as any);
        }
        setIsModalOpen((prev) => ({ ...prev, modal: false }));
      } else {
        setError(res?.error as string);
      }
    } catch (error) {
      console.error("Failed to add category to Firebase:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEdit = (category: any) => {
    setEditingCategory(category);
    setIsModalOpen((prev) => ({ ...prev, modal: true, isEdit: true }));
  };

  const handleDelete = async (id: string) => {
    const usersWithCategory = state?.users?.filter((user) =>
      user.categoryIds?.includes(id)
    );
    const categoryToDelete = state.users.some(
      (user) => user.categoryIds?.includes(id) && user.categoryIds?.length === 1
    );

    if (categoryToDelete) {
      setIsModalOpen((prev) => ({ ...prev, assignCategoryModal: true }));
    } else {
      if (confirm("Are you sure you want to delete this category?")) {
        dispatch({ type: "DELETE_CATEGORY", payload: id } as any);
        usersWithCategory.forEach((user) => {
          const userRef = doc(db, "users", user.id);
          const updatedCategoryIds = user.categoryIds?.filter(
            (existingCategoryId: string) => existingCategoryId !== id
          );
          updateDoc(userRef, {
            officeIds: updatedCategoryIds,
          });
        });
        let notificationPromises = [];
        for (const user of usersWithCategory) {
          notificationPromises.push(
            insertNotification({
              title: "Category Removed",
              message: `You've been removed from the category: "${getCategoryName(
                id
              )}".`,
              userId: user.id,
              workspaceId: user.workspaceId as string,
              key: "category",
            })
          );
        }
        await Promise.all(notificationPromises);
      }
    }
  };

  const handleAssignCategory = async (e: React.FormEvent) => {
    e.preventDefault();
    const usersWithCategory = state?.users?.filter((user) =>
      user.categoryIds?.includes(selectedCategoryId.toString() ?? "")
    );
    try {
      setIsSubmitting(true);
      if (usersWithCategory.length > 0) {
        for (const user of usersWithCategory) {
          const userRef = doc(db, "users", user.id);
          const updatedCategoriesId = user.categoryIds?.filter(
            (existingCategoryId: string) =>
              user.role === "ADMIN" &&
              existingCategoryId.toString() !== selectedCategoryId.toString()
          );
          await updateDoc(userRef, {
            categoryIds:
              user.categoryIds?.length === 1
                ? [categoryId]
                : updatedCategoriesId,
          });
        }
        let notificationPromises = [];
        for (const user of usersWithCategory) {
          if (user.categoryIds?.length === 1) {
            notificationPromises.push(
              insertNotification({
                userId: user.id,
                title: "Category removed",
                message: `Your category: "${getCategoryName(
                  selectedCategoryId ?? ""
                )}" has been replaced with category: "${getCategoryName(
                  selectedCategoryId ?? ""
                )}" by ${auth?.user?.name}.`,
                workspaceId: user?.workspaceId ?? "",
                key: "category",
              })
            );
          } else {
            notificationPromises.push(
              insertNotification({
                userId: user.id,
                title: "Category removed",
                message: `You've been removed from the category: "${getCategoryName(
                  selectedCategoryId ?? ""
                )}".`,
                workspaceId: user?.workspaceId ?? "",
                key: "category",
              })
            );
          }
        }
        await Promise.all(notificationPromises);
      } else {
        console.log("No users with this category");
      }
      dispatch({
        type: "DELETE_CATEGORY",
        payload: selectedCategoryId.toString() || "",
      });
      console.log(
        `Category with ID: ${selectedCategoryId} has been deleted successfully.`
      );
      setIsSubmitting(false);
      setCategoryId(null);
      setIsModalOpen((prev) => ({ ...prev, assignCategoryModal: false }));
    } catch (error) {
      console.error(
        `Error deleting category with ID: ${selectedCategoryId}`,
        error
      );
      dispatch({
        type: "SET_ERROR",
        payload: "Failed to delete category",
      });
    }
  };

  console.log({ selectedCategoryId });

  return (
    <div className="mx-auto max-w-7xl">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">Categories</h1>
        <Button
          onClick={() => {
            setEditingCategory(null);
            setIsModalOpen((prev) => ({ ...prev, modal: true }));
          }}
        >
          Add Category
        </Button>
      </div>

      <div className="mt-8 px-4 lg:px-0">
        {state.categories.length === 0 ? (
          <EmptyState />
        ) : (
          <CategoryTable
            setSelectedCategoryId={setSelectedCategoryId}
            categories={state.categories}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        )}
      </div>

      <CategoryForm
        error={error}
        isOpen={isModalOpen.modal}
        onClose={() => {
          setIsModalOpen((prev) => ({ ...prev, modal: false }));
          setSelectedCategoryId(null);
          setError(null);
          setEditingCategory(null);
        }}
        isEdit={isModalOpen.isEdit}
        categories={state.categories}
        loading={isSubmitting}
        onSubmit={handleSubmit}
        editingCategory={editingCategory}
        setEditingCategory={setEditingCategory}
      />
      <Modal
        isOpen={isModalOpen.assignCategoryModal}
        onClose={() => {
          setIsModalOpen((prev) => ({ ...prev, assignCategoryModal: false }));
          setCategoryId(null);
        }}
        title={"User Reassignment Needed for Category Deletion"}
      >
        <p>
          The Category you are trying to delete is currently assigned to one or
          more users. To proceed with deletion, you must assign the user(s) to a
          different category.
        </p>
        <form className="mt-5" onSubmit={(e) => handleAssignCategory(e)}>
          <div>
            <Space className="w-full" direction="vertical">
              <Select
                disabled={false}
                allowClear
                className="w-full mt-1"
                placeholder="Please select Category"
                value={categoryId?.toString()}
                onChange={(value) => {
                  setCategoryId(value);
                }}
                options={state?.categories
                  ?.filter(
                    (category: any) => category.id !== selectedCategoryId
                  )
                  .map((category: any) => {
                    return {
                      label: category?.name,
                      value: category?.id,
                    };
                  })}
              />
            </Space>
          </div>

          <Button
            type="submit"
            className="w-full sm:w-auto mt-5"
            disabled={isSubmitting}
          >
            Submit
            {isSubmitting && <ButtonLoader />}
          </Button>
        </form>
      </Modal>
    </div>
  );
}
