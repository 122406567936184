import { Routes, Route, Navigate } from "react-router-dom";
import RequireAuth from "./components/RequireAuth";
import RequireRole from "./components/RequireRole";
import DashboardLayout from "./layouts/DashboardLayout";

// Pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import Requests from "./pages/Requests";
import NewRequest from "./pages/NewRequest";
import Settings from "./pages/Settings";
import Support from "./pages/Support";
import Offices from "./pages/admin/Offices";
import Categories from "./pages/admin/Categories";
import Users from "./pages/admin/Users";
import AuthActions from "./pages/AuthActions";
import NoAuth from "./components/NoAuth";
import OwnerRequired from "./components/OwnerRequired";
import SuperAdmins from "./pages/owner/SuperAdmins";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import NotFound from "./pages/NotFound";
import { useScreenSize } from "./hooks/useScreenSize";
import BulkUserCreation from "./pages/admin/BulkUserCreation";
import ForgotPassword from "./pages/ForgotPassword";

export default function AppRoutes() {
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const screenSize = useScreenSize();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  useEffect(() => {
    if (screenSize < 1024) {
      setIsCollapsed(false);
    } else {
      setIsCollapsed(true);
    }
  }, [location.pathname, screenSize]);

  useEffect(() => {
    if (screenSize < 1024) {
      setIsCollapsed(false);
    }
  }, []);
  console.log(screenSize);

  return (
    <Routes>
      {/* Public routes */}
      <Route
        path="/"
        element={
          <NoAuth>
            <Home />
          </NoAuth>
        }
      />
      <Route
        path="/auth/action"
        element={
          <NoAuth>
            <AuthActions />
          </NoAuth>
        }
      />
      <Route
        path="/login"
        element={
          <NoAuth>
            <Login />
          </NoAuth>
        }
      />
      <Route
        path="/reset-password"
        element={
          <NoAuth>
            <ForgotPassword />
          </NoAuth>
        }
      />
      <Route
        path="/register"
        element={
          <NoAuth>
            <Register />
          </NoAuth>
        }
      />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      {/* Protected routes */}
      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <DashboardLayout
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
            >
              <Dashboard />
            </DashboardLayout>
          </RequireAuth>
        }
      />

      {/* Agent routes */}
      <Route
        path="/dashboard/requests"
        element={
          <RequireAuth>
            <DashboardLayout
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
            >
              <Requests />
            </DashboardLayout>
          </RequireAuth>
        }
      />

      <Route
        path="/dashboard/requests/new"
        element={
          <RequireAuth>
            <RequireRole roles={["AGENT"]}>
              <DashboardLayout
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
              >
                <NewRequest />
              </DashboardLayout>
            </RequireRole>
          </RequireAuth>
        }
      />

      <Route
        path="/dashboard/settings"
        element={
          <RequireAuth>
            <DashboardLayout
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
            >
              <Settings />
            </DashboardLayout>
          </RequireAuth>
        }
      />

      <Route
        path="/dashboard/support"
        element={
          <RequireAuth>
            <DashboardLayout
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
            >
              <Support />
            </DashboardLayout>
          </RequireAuth>
        }
      />

      {/* Admin only routes */}

      <Route
        path="/dashboard/admin/approvals"
        element={
          <RequireAuth>
            <RequireRole roles={["SUPER_ADMIN"]}>
              <OwnerRequired>
                <DashboardLayout
                  isCollapsed={isCollapsed}
                  setIsCollapsed={setIsCollapsed}
                >
                  <SuperAdmins />
                </DashboardLayout>
              </OwnerRequired>
            </RequireRole>
          </RequireAuth>
        }
      />

      <Route
        path="/dashboard/admin/offices"
        element={
          <RequireAuth>
            <RequireRole roles={["SUPER_ADMIN"]}>
              <DashboardLayout
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
              >
                <Offices />
              </DashboardLayout>
            </RequireRole>
          </RequireAuth>
        }
      />

      <Route
        path="/dashboard/admin/categories"
        element={
          <RequireAuth>
            <RequireRole roles={["SUPER_ADMIN"]}>
              <DashboardLayout
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
              >
                <Categories />
              </DashboardLayout>
            </RequireRole>
          </RequireAuth>
        }
      />

      <Route
        path="/dashboard/admin/users"
        element={
          <RequireAuth>
            <RequireRole roles={["SUPER_ADMIN", "ADMIN"]}>
              <DashboardLayout
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
              >
                <Users />
              </DashboardLayout>
            </RequireRole>
          </RequireAuth>
        }
      />

      {/* Catch all route */}
      <Route path="*" element={<NotFound />} />
      <Route
        path="/dashboard/admin/bulk-user-creation"
        element={
          <RequireAuth>
            <RequireRole roles={["SUPER_ADMIN", "ADMIN"]}>
              <DashboardLayout
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
              >
                <BulkUserCreation />
              </DashboardLayout>
            </RequireRole>
          </RequireAuth>
        }
      />
    </Routes>
  );
}
