import { useNavigate } from "react-router-dom";
import { MessageSquare, Activity } from "lucide-react";
import Button from "@/components/ui/Button";
import { useAuth } from "@/context/AuthContext";
import { useRequests } from "@/context/RequestsContext";
import { cn } from "@/lib/utils";
import { useEffect } from "react";

export default function UserDashboard() {
  const navigate = useNavigate();
  const { state: requestState } = useRequests();
  const {
    state: { user },
  } = useAuth();
  if (!user) return null;
  const req = requestState?.loadedSuggestion
    ? requestState?.suggestedRequests
    : requestState?.requests;

  // Calculate user-specific stats
  const activeRequests = req.filter(
    (request) => request.agentId === user.id && request.status !== "Completed"
  ).length;

  const recentActivity = req.filter((request) => {
    const requestDate = new Date(request.createdAt);
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    return request.agentId === user.id && requestDate >= oneWeekAgo;
  }).length;

  const stats = [
    {
      name: "Active Requests",
      value: activeRequests.toString(),
      icon: MessageSquare,
    },
    { name: "Weekly Activity", value: `${recentActivity} new`, icon: Activity },
  ];

  const uniqueArray = req?.filter(
    (request, index, self) =>
      index === self.findIndex((r) => r.id === request.id)
  );

  // Get user's requests
  const userRequests = uniqueArray
    .filter((request) => request.agentId === user.id)
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
    .slice(0, 5);

  const handleNewRequest = () => {
    navigate("/dashboard/requests/new");
  };

  const handleRequestClick = (requestId: string) => {
    navigate("/dashboard/requests", { state: { scrollToId: requestId } });
  };

  useEffect(() => {
    if (requestState.loadedSuggestion) {
      const elem = document.getElementById("requests");
      elem?.scrollIntoView({ behavior: "smooth" });
    }
  }, [requestState.loadedSuggestion]);

  return (
    <div className="mx-auto max-w-7xl overflow-x-auto">
      <div className="flex md:items-center flex-col md:flex-row gap-3 md:gap-0 justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">My Dashboard</h1>
        <Button
          onClick={handleNewRequest}
          className="flex items-center space-x-2 w-fit"
        >
          <MessageSquare className="h-4 w-4" />
          <span>New Request</span>
        </Button>
      </div>

      <div className="mt-8 grid grid-cols-1 gap-4 sm:grid-cols-2">
        {stats.map((stat) => (
          <div
            key={stat.name}
            className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
          >
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <stat.icon
                  className="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    {stat.name}
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {stat.value}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-8" id="requests">
        <h2 className="text-lg font-medium text-gray-900">Recent Requests</h2>
        <div className="mt-4 overflow-hidden rounded-lg bg-white shadow">
          {userRequests.length === 0 ? (
            <div className="p-6 text-center text-gray-500">
              No requests yet.{" "}
              <button
                onClick={handleNewRequest}
                className="text-blue-600 hover:text-blue-700 font-medium"
              >
                Create your first request!
              </button>
            </div>
          ) : (
            <ul role="list" className="divide-y divide-gray-200">
              {userRequests.map((request) => (
                <li
                  key={request.id}
                  onClick={() => handleRequestClick(request.id)}
                  className="px-6 py-4 hover:bg-gray-50 cursor-pointer transition-colors"
                >
                  <div className="flex flex-col sm:flex-row items-start gap-3 sm:gap-0 sm:items-center justify-between">
                    <div>
                      <p className="font-medium text-gray-900">
                        {request.title}
                      </p>
                      <div className="mt-1 flex items-center flex-wrap sm:flex-nowrap gap-1 sm:gap-2 text-sm text-gray-500">
                        <span>{request.category}</span>
                        <span className="hidden sm:block">•</span>
                        <span>
                          {new Date(request.createdAt).toLocaleString()}
                        </span>
                      </div>
                    </div>
                    <span
                      className={cn(
                        "inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium",
                        {
                          "bg-blue-100 text-blue-800":
                            request.status === "In Progress",
                          "bg-yellow-100 text-yellow-800":
                            request.status === "Pending",
                          "bg-green-100 text-green-800":
                            request.status === "Completed",
                        }
                      )}
                    >
                      {request.status}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}
