import { sendCustomMail } from "@/api/sendEmail";

export const generateApprovalEmailFunction = async ({
  name,
  email,
}: {
  name: string;
  email: string;
}) => {
  try {
    await sendCustomMail({
      body: {
        data:{
            name,
            email
        },
        template_name: "approval-email-template",
      },
    });
  } catch (error) {
    console.log("Error sending email to user", error);
    throw error;
  }
};
