import { useRef, useState, useEffect } from "react";

const TableScrollWrapper = ({ children, classes }: any) => {
  const scrollableRef = useRef<HTMLDivElement | null>(null);
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);

  const handleMouseDown = (e: any) => {
    if (scrollableRef.current) {
      setIsDown(true);
      setStartX(e.pageX - scrollableRef.current.offsetLeft);
      setScrollLeft(scrollableRef.current.scrollLeft);
      scrollableRef.current.classList.add("active");
    }
  };

  const handleMouseLeave = () => {
    freeScroll();
  };

  const handleMouseUp = () => {
    freeScroll();
  };

  const handleMouseMove = (e: any) => {
    if (!isDown || !scrollableRef.current) return;
    e.preventDefault();
    setIsScrolling(true);
    const x = e.pageX - scrollableRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    scrollableRef.current.scrollLeft = scrollLeft - walk;
  };


  const freeScroll = () => {
    setIsDown(false);
    setIsScrolling(false);
    if (scrollableRef.current) {
      scrollableRef.current.classList.remove("active");
    }
  };

  useEffect(()=>{
  }, [scrollableRef, scrollableRef?.current?.scrollLeft])

  return (
    <div
      ref={scrollableRef}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      className={`${classes} ${
        isDown ? "cursor-grabbing" : "cursor-grab select-text"
      } ${isScrolling ? "select-none" : ""}`}
    >
      {children}
    </div>
  );
};

export default TableScrollWrapper;
