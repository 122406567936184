import { useEffect, useRef, useState } from "react";
import { Bell } from "lucide-react";
import { Notification, useNotifications } from "@/context/NotificationContext";
import { useAuth } from "@/context/AuthContext";
import Button from "./ui/Button";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { isOwner } from "./OwnerRequired";
import "./NotificationBell.scss";
import { useRequests } from "@/context/RequestsContext";
import "./NotificationBell.scss";

export default function NotificationBell() {
  const [isOpen, setIsOpen] = useState(false);
  const { state, markAsRead, markAsAllRead } = useNotifications();
  const { getRequest } = useRequests();
  const { state: authState } = useAuth();
  const navigate = useNavigate();

  if (!authState.user) return null;

  const handleNotificationClick = async (notification: Notification) => {
    await markAsRead(notification?.id);
    setIsOpen(false);
    if (
      authState.isAuthenticated &&
      authState?.user?.workspaceId === notification?.workspaceId &&
      notification?.requestId !== null
    ) {
      await getRequest(notification?.requestId);
      navigate(`/dashboard/requests`, {
        state: { scrollToId: notification.requestId },
      });
    }
    if (
      authState?.user?.role === "SUPER_ADMIN" &&
      authState.isAuthenticated &&
      authState?.user?.workspaceId === notification?.workspaceId &&
      notification?.userId !== null && notification?.key === 'users'
    ) {
      
      navigate(`/dashboard/admin/users`, {
        state: { scrollToId: notification.userId },
      });
    }
    if(isOwner(authState?.user?.email as string)&& notification?.approval ){
      navigate(`/dashboard/admin/approvals`,{
        state: { scrollToId: notification.userId }
    });
    }
    if (notification?.type === "user_onboard") {
      navigate(`/dashboard/admin/users`, {
        state: { scrollToId: notification.userId },
      });
    }
  };

  const markAllAsRead = async () => {
    await markAsAllRead();
  };

  const useOutsideClick = (callback: () => void) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent | TouchEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          callback();
        }
      };

      document.addEventListener("mouseup", handleClickOutside);
      document.addEventListener("touchend", handleClickOutside);

      return () => {
        document.removeEventListener("mouseup", handleClickOutside);
        document.removeEventListener("touchend", handleClickOutside);
      };
    }, [isOpen]);

    return ref;
  };

  const ref = useOutsideClick(() => {
    setIsOpen(false);
  });

  return (
    <div ref={ref} className="md:relative">
      <Button
        variant="outline"
        size="sm"
        onClick={() => setIsOpen(!isOpen)}
        className="relative"
      >
        <Bell className="h-4 w-4" />
        {state?.notifications.filter((n) => !n.read).length > 0 && (
          <span className="absolute -top-1 -right-1  md:right-0 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs text-white">
            {state?.notifications.filter((n) => !n.read).length}
          </span>
        )}
      </Button>

      {isOpen && (
        <div className="absolute pb-5 right-6 lg:right-0 mt-2 w-52 md:w-60 lg:w-80 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 z-[200] min-w-60 sm:min-w-80 ">
          <div className="">
            <div className="flex items-center justify-between px-4 pt-4">
              <h3 className="text-sm font-medium text-gray-900  w-full">
                Notifications
              </h3>
              {state?.notifications.filter((n) => !n.read).length > 0 && (
                <button
                  onClick={markAllAsRead}
                  className="text-sm text-blue-600 hover:text-blue-500 whitespace-nowrap"
                >
                  Mark all as read
                </button>
              )}
            </div>
            <div
              className={clsx(
                "space-y-4 max-h-96 overflow-y-auto table-mobile-scroll",
                state?.notifications?.length > 0 && " px-4 py-4"
              )}
            >
              {state?.notifications?.length === 0 ? (
                <p className="text-sm text-gray-500 text-center py-4">
                  No notifications
                </p>
              ) : (
                state?.notifications
                  ?.sort((a, b) => {
                    const dateA = a.createdAt?.toDate();
                    const dateB = b.createdAt?.toDate();
                    return dateB?.getTime() - dateA?.getTime();
                  })
                  ?.map((notification, i) => (
                    <div className="relative">
                      <button
                        key={notification?.id + i}
                        onClick={() => handleNotificationClick(notification)}
                        className={`w-full text-left p-3 rounded-md transition-colors bg-gray-50 hover:bg-gray-100 overflow-hidden cursor-pointer`}
                      >
                        <p className="text-sm font-medium text-gray-900">
                          {notification.title}
                        </p>

                        <p className="text-sm text-gray-500 mt-1">
                          {notification.message}
                        </p>
                        <p className="text-xs text-gray-400 mt-1">
                          {notification.createdAt?.toDate().toDateString()}
                        </p>
                      </button>
                      {!notification?.read && (
                        <p className="flex absolute -top-1 -right-1  rounded-md px-1 py items-center justify-center  bg-red-500 text-xs text-white ">
                          New
                        </p>
                      )}
                    </div>
                  ))
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
