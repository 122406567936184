import {useState, useEffect} from "react";

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState<number>(1024);

  useEffect(() => {
    const handleResize = () => {
        setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
return screenSize
}