const sendSmsEndpoint =
  import.meta.env.VITE_FIREBASE_CLOUD_URL + "/sendSms";

export const sendSms = async ({
  body,
}: {
  body: Record<string, any>;
}) => {
  return await fetch(sendSmsEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body),
  });
};