import React, { useState, useEffect } from "react";
import {
  Building2,
  Home,
  MessageSquare,
  Settings,
  Users,
  FolderCog,
  Building,
  UserCog,
  UserPlus,
  ChevronLeft,
  ChevronRight,
  User,
} from "lucide-react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { cn } from "@/lib/utils";
import { useAuth } from "@/context/AuthContext";
import Button from "@/components/ui/Button";
import { logoStorage } from "@/lib/logo";
import { isOwner } from "./OwnerRequired";

interface Props {
  isCollapsed: boolean;
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Sidebar({ isCollapsed, setIsCollapsed}: Props) {
  const { state } = useAuth();
  const user = state.user;
  const nav = useLocation();

  const [logos, setLogos] = useState({
    icon: "/images/logo-icon.png",
    full: "/images/logo-full.png",
  });



  useEffect(() => {
    const loadLogos = async () => {
      try {
        const currentLogos = await logoStorage.get();
        setLogos({
          icon: currentLogos.icon || "/images/logo-icon.png",
          full: currentLogos.full || "/images/logo-full.png",
        });
      } catch (error) {
        console.warn("Failed to load logos:", error);
      }
    };

    loadLogos();
  }, []);

  if (!user) return null;

  // Define navigation items based on user role
  let navigationItems = [{ name: "Dashboard", href: "/dashboard", icon: Home }];

  // Agent-specific navigation
  if (user.role === "AGENT") {
    navigationItems = [
      ...navigationItems,
      { name: "Requests", href: "/dashboard/requests", icon: MessageSquare },
      { name: "Settings", href: "/dashboard/settings", icon: Settings },
    ];
  }

  // Admin navigation
  else if (user.role === "ADMIN") {
    navigationItems = [
      ...navigationItems,
      { name: "Requests", href: "/dashboard/requests", icon: MessageSquare },
      { name: "Users", href: "/dashboard/admin/users", icon: UserCog },
      { name: "Settings", href: "/dashboard/settings", icon: Settings },
    ];
  }

  // Super Admin navigation
  else if (user.role === "SUPER_ADMIN") {
    if (isOwner(user.email)) {
      navigationItems = [
        ...navigationItems,
        { name: "Approvals", href: "/dashboard/admin/approvals", icon: Users },
      ];
    }
    navigationItems = [
      ...navigationItems,
      { name: "Requests", href: "/dashboard/requests", icon: MessageSquare },
      { name: "Offices", href: "/dashboard/admin/offices", icon: Building },
      {
        name: "Categories",
        href: "/dashboard/admin/categories",
        icon: FolderCog,
      },
      { name: "Users", href: "/dashboard/admin/users", icon: UserCog },
      { name: "Bulk Users Creation", href: "/dashboard/admin/bulk-user-creation", icon: UserPlus },
      { name: "Settings", href: "/dashboard/settings", icon: Settings },
      { name: "Support", href: "/dashboard/support", icon: User },
    ];
  }

  return (
    <div
      className={cn(
        "flex flex-col bg-gray-900 transition-all overflow-hidden fixed top-0 left-0 h-screen lg:sticky z-50",
        isCollapsed ? "w-64 lg:w-64" : `w-16 lg:w-16`
      )}
      // className={clsx(
      //   "flex flex-col bg-gray-900  overflow-hidden",
      //   isCollapsed ? "w-64 lg:w-16 absolute top-0 left-0 h-full z-50 lg:static" : `w-16 lg:w-64 static`
      // )}
    >
      <div
        className={cn(
          "flex h-16 items-center border-b border-gray-800 px-4",
          isCollapsed
            ? "justify-between lg:justify-center"
            : "justify-center lg:justify-between"
        )}
      >
        {isCollapsed && (
          <div className="flex items-center text-white font-bold text-base">
            <Link
              to="/dashboard"
              className="flex items-center text-white font-bold text-base"
            >
              <img
                src="/images/logo-icon.png"
                alt="Victoria"
                className="h-8 w-8 block md:hidden"
              />
              <img
                src="/images/logo-full.png"
                alt="Victoria"
                className={`pr-3 hidden md:block  `}
              />
            </Link>
          </div>
        )}

        <Button
          variant="ghost"
          size="sm"
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="text-gray-400 hover:text-white md:hover:text-black hover:bg-[#111827] md:hover:bg-white"
        >
          {isCollapsed ? (
            <>
              <ChevronLeft className="h-5 w-5 lg:block hidden" />
              <ChevronLeft className="h-5 w-5 lg:hidden" />
            </>
          ) : (
            <>
              <ChevronRight className="h-5 w-5 lg:block hidden" />
              <ChevronRight className="h-5 w-5 lg:hidden " />
            </>
          )}
        </Button>
      </div>
      <nav className="flex-1 space-y-1 px-2 py-4">
        {navigationItems.map((item) => (
          <NavLink
            key={item.name}
            to={item.href}
            className={() =>
              cn(
                "group flex items-center rounded-md px-2 py-2 text-sm font-medium transition-colors",
                nav?.pathname === item?.href
                  ? "bg-gray-800 text-white"
                  : "transition-colors text-gray-300 hover:bg-gray-700 hover:text-white",
                isCollapsed
                  ? "justify-start lg:justify-start"
                  : "justify-center lg:justify-center"
              )
            }
            title={isCollapsed ? item.name : undefined}
          >
            <item.icon
              className={cn(
                "flex-shrink-0",
                isCollapsed ? "h-6 w-6 lg:mr-3 mr-3" : " h-5 w-5 mr-0 lg:mr-0"
              )}
              aria-hidden="true"
            />
            <span
              className={cn(
                "transition-all delay-500",
                !isCollapsed ? "lg:hidden hidden" : "lg:block"
              )}
            >
              {item.name}
            </span>
          </NavLink>
        ))}
      </nav>
    </div>
  );
}
