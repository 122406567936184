import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/context/AuthContext";
import { useAdmin } from "@/context/AdminContext";
import Button from "@/components/ui/Button";
import { logoStorage } from "@/lib/logo";
import FileUpload from "@/components/ui/FileUpload";
import {
  updatePassword as firebaseUpdatePassword,
  getAuth,
  User as FirebaseUser,
  reauthenticateWithCredential,
} from "firebase/auth";
import { EmailAuthProvider } from "firebase/auth/cordova";
import { auth, signOut } from "../lib/firebase";
import PhoneNumberInput from "@/components/PhoneInput";
import { updateUser } from "@/api/user";
import { MessageCircleWarningIcon } from "lucide-react";
import OpenAiConfiguration from "@/components/OpenAiConfiguration";

export default function Settings() {
  const navigate = useNavigate();
  const { state: authState, dispatch: authDispatch, getToken } = useAuth();
  const { dispatch: adminDispatch } = useAdmin();
  const user = authState.user;

  const [formData, setFormData] = useState({
    name: user?.name || "",
    email: user?.email || "",
    phoneNumber: user?.phoneNumber || "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [disabled, setDisabled] = useState(false);
  const [iconLogo, setIconLogo] = useState<File | null>(null);
  const [fullLogo, setFullLogo] = useState<File | null>(null);
  const [passwordVisibility, setPasswordVisibility] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [successPassword, setSuccessPassword] = useState<string | null>(null);
  const [errorPassword, setErrorPassword] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingPassword, setIsSubmittingPassword] = useState(false);
  const [logos, setLogos] = useState({
    icon: "/images/logo-icon.png",
    full: "/images/logo-full.png",
  });

  useEffect(() => {
    const loadLogos = async () => {
      const currentLogos = await logoStorage.get();
      setLogos({
        icon: currentLogos.icon || "/images/logo-icon.png",
        full: currentLogos.full || "/images/logo-full.png",
      });
    };
    loadLogos();
  }, []);

  if (!user) {
    navigate("/login");
    return null;
  }

  const handleShowPassword = (field: "current" | "new" | "confirm") => {
    setPasswordVisibility((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setIsSubmitting(true);

    try {
      // Validate inputs
      if (!formData.name.trim() || !formData.email.trim()) {
        throw new Error("Name and email are required.");
      }

      const updatedUser = {
        ...user,
        name: formData.name,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
      };

      if (authState?.user) {
        const currentUser = auth?.currentUser as any;
        console.log(currentUser);

        if (currentUser.emailVerified) {
          const token = (await getToken()) as string;
          const res: any = await updateUser({
            token,
            body: {
              newEmail: formData.email,
              userId: user.id,
              phoneNumber: formData.phoneNumber,
              name: formData.name,
              type: "updateProfile",
            },
          });
          adminDispatch({ type: "UPDATE_USER", payload: updatedUser });
          authDispatch({ type: "UPDATE_USER", payload: updatedUser });

          if (res.status !== 200) {
            const errorText = await res.json();
            throw new Error(errorText.message);
          }
          if (user.email !== formData.email) {
            await signOut();
          }
          // Success message
          setSuccess("Settings updated successfully.");
        } else {
          throw new Error("Please verify your new email before updating.");
        }
      } else {
        console.error("authState or user is undefined");
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorPassword(null);
    setSuccessPassword(null);
    setIsSubmittingPassword(true);

    try {
      // Validate inputs
      if (!formData.name.trim() || !formData.email.trim()) {
        throw new Error("Name and email are required.");
      }

      if (formData.email !== user.email && !formData.currentPassword) {
        throw new Error("Current password is required to change email.");
      }

      if (formData.newPassword) {
        if (!formData.currentPassword) {
          throw new Error(
            "Current password is required to set a new password."
          );
        }
        if (formData.newPassword.length < 8) {
          throw new Error("New password must be at least 8 characters long.");
        }
        if (formData.currentPassword === formData.newPassword) {
          setErrorPassword(
            "Your new password must be different from the current password."
          );
          return;
        }
        if (formData.newPassword !== formData.confirmPassword) {
          setErrorPassword("Passwords do not match.");
          return;
        }

        // Change password
        const auth = getAuth();
        const currentUser = auth.currentUser as FirebaseUser;

        if (currentUser) {
          try {
            const credential = EmailAuthProvider.credential(
              user.email,
              formData.currentPassword
            );
            await reauthenticateWithCredential(currentUser, credential);
            await firebaseUpdatePassword(currentUser, formData.newPassword);
          } catch (error: any) {
            throw new Error(
              "The current password you entered is incorrect. Please try again."
            );
          }
        } else {
          throw new Error("User is not authenticated.");
        }
      }

      // Update user
      const updatedUser = {
        ...user,
        name: formData.name,
        email: formData.email,
      };

      // Update in both contexts
      adminDispatch({ type: "UPDATE_USER", payload: updatedUser });
      authDispatch({ type: "UPDATE_USER", payload: updatedUser });
      // Success message
      setSuccessPassword("Changed password successfully.");
      setFormData((prev) => ({
        ...prev,
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      }));
    } catch (err) {
      setErrorPassword(
        err instanceof Error ? err.message : "An error occurred."
      );
    } finally {
      setIsSubmittingPassword(false);
    }
  };

  const handleLogoUpload = async (files: File[], type: "icon" | "full") => {
    if (files.length === 0) return;

    const file = files[0];
    if (type === "icon") {
      setIconLogo(file);
    } else {
      setFullLogo(file);
    }

    try {
      // Convert File to data URL
      const reader = new FileReader();
      const dataUrl = await new Promise<string>((resolve, reject) => {
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });

      // Save to storage
      const success = await logoStorage.set(type, dataUrl);

      if (success) {
        setLogos((prev) => ({
          ...prev,
          [type]: dataUrl,
        }));
        setSuccess(
          `${type === "icon" ? "Icon" : "Full"} logo updated successfully`
        );
      } else {
        throw new Error("Failed to save logo");
      }
    } catch (err) {
      console.error("Error uploading logo:", err);
      setError(`Failed to update ${type} logo`);
    }
  };

  useEffect(() => {
    if (
      user.email !== formData.email ||
      user.name !== formData.name ||
      user.phoneNumber !== formData.phoneNumber
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [user, formData]);

  return (
    <div className="mx-auto max-w-2xl ">
      <h1 className="text-2xl font-semibold text-gray-900 mb-8">
        Account Settings
      </h1>
      <div className="space-y-6 bg-white p-6 rounded-lg shadow mt-5">
        {/* {user.role === "SUPER_ADMIN" && (
        <div className="bg-white p-6 rounded-lg shadow mb-8">
          <h2 className="text-xl font-semibold text-gray-900 mb-6">
            Logo Management
          </h2>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            <div>
              <h3 className="text-lg font-medium text-gray-700 mb-4">
                Icon Logo
              </h3>
              <div className="border-2 border-dashed border-gray-300 rounded-lg p-4">
                <img
                  src={logos.icon}
                  alt="Icon logo"
                  className="w-32 h-32 mx-auto mb-4 object-contain"
                />
                <FileUpload
                  onFileSelect={(files) => handleLogoUpload(files, "icon")}
                  accept="image/*"
                  multiple={false}
                />
              </div>
            </div>

            <div>
              <h3 className="text-lg font-medium text-gray-700 mb-4">
                Full Logo
              </h3>
              <div className="border-2 border-dashed border-gray-300 rounded-lg p-4">
                <img
                  src={logos.full}
                  alt="Full logo"
                  className="h-32 mx-auto mb-4 object-contain"
                />
                <FileUpload
                  onFileSelect={(files) => handleLogoUpload(files, "full")}
                  accept="image/*"
                  multiple={false}
                />
              </div>
            </div>
          </div>
        </div>
      )} */}

        {user.role === "SUPER_ADMIN" && <OpenAiConfiguration />}

        <form onSubmit={handleSubmit}>
          {error && (
            <div className="rounded-md bg-red-50 p-4">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          )}

          {success && (
            <div className="rounded-md bg-green-50 p-4">
              <p className="text-sm text-green-700">{success}</p>
            </div>
          )}

          <div>
            <h2 className="text-lg font-medium text-gray-900 mb-5 mt-3">
              Update Profile
            </h2>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              className="mt-2 p-2 border w-full rounded-md  border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              required
            />
          </div>

          <div className="mt-5">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email <br />
              <span className="text-xs text-gray-500 flex items-center gap-1 mt-1">
                <MessageCircleWarningIcon
                  height={15}
                  width={15}
                  className="w-9 h-9 md:w-auto md:h-auto"
                />{" "}
                Updating your email address will log you out, and you'll need to
                sign in again.
              </span>
            </label>
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              className="mt-2 p-2 border w-full rounded-md  border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              required
            />
          </div>
          <div className="mt-5">
            <label
              htmlFor="phoneNumber"
              className="block text-sm font-medium text-gray-700"
            >
              Phone Number
            </label>
            <PhoneNumberInput
              required={true}
              value={formData?.phoneNumber}
              handleChange={(e) => setFormData({ ...formData, phoneNumber: e })}
              name="phoneNumber"
              variant="secondary"
            />
          </div>

          <div className="flex justify-end pt-6">
            <Button type="submit" disabled={isSubmitting || disabled}>
              {isSubmitting ? "Saving..." : "Save Changes"}
            </Button>
          </div>
        </form>

        <form
          onSubmit={handleSubmitPassword}
          className="mt-5 border-t-[1px] border-gray-200"
        >
          {errorPassword && (
            <div className="rounded-md bg-red-50 p-4 mt-3">
              <p className="text-sm text-red-700">{errorPassword}</p>
            </div>
          )}

          {successPassword && (
            <div className="rounded-md bg-green-50 p-4 mt-3">
              <p className="text-sm text-green-700">{successPassword}</p>
            </div>
          )}

          <div className="space-y-6 pt-6">
            <h2 className="text-lg font-medium text-gray-900">
              Change Password
            </h2>

            <div>
              <label
                htmlFor="currentPassword"
                className="block text-sm font-medium text-gray-700"
              >
                Current Password
              </label>
              <div className="relative mt-2">
                <input
                  type={passwordVisibility.current ? "text" : "password"}
                  id="currentPassword"
                  name="currentPassword"
                  value={formData.currentPassword}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      currentPassword: e.target.value,
                    })
                  }
                  required
                  className="p-2 border w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
                {formData.currentPassword && (
                  <button
                    type="button"
                    onClick={() => handleShowPassword("current")}
                    className="absolute right-2 top-2 text-gray-500"
                  >
                    {passwordVisibility.current ? "Hide" : "Show"}
                  </button>
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="newPassword"
                className="block text-sm font-medium text-gray-700"
              >
                New Password
              </label>
              <div className="relative mt-2">
                <input
                  type={passwordVisibility.new ? "text" : "password"}
                  id="newPassword"
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={(e) =>
                    setFormData({ ...formData, newPassword: e.target.value })
                  }
                  required
                  className="p-2 border w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
                {formData.newPassword && (
                  <button
                    type="button"
                    onClick={() => handleShowPassword("new")}
                    className="absolute right-2 top-2 text-gray-500"
                  >
                    {passwordVisibility.new ? "Hide" : "Show"}
                  </button>
                )}
              </div>
            </div>

            <div>
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium text-gray-700"
              >
                Confirm New Password
              </label>
              <div className="relative mt-2">
                <input
                  type={passwordVisibility.confirm ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      confirmPassword: e.target.value,
                    })
                  }
                  required
                  className="p-2 border w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
                {formData.confirmPassword && (
                  <button
                    type="button"
                    onClick={() => handleShowPassword("confirm")}
                    className="absolute right-2 top-2 text-gray-500"
                  >
                    {passwordVisibility.confirm ? "Hide" : "Show"}
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-end pt-6">
            <Button type="submit" disabled={isSubmittingPassword}>
              {isSubmittingPassword ? "Saving..." : "Update Password"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
