import {
  collection,
  serverTimestamp,
  addDoc,
  doc,
  setDoc,
} from "firebase/firestore";
import { db } from "@/lib/firebase/app";

export const getNotificationRef = (id: string) =>
  collection(db, `users/${id}/notifications`);

export const insertNotification = async ({
  title,
  message,
  userId,
  requestId,
  workspaceId,
  key
}: {
  title: string;
  message: string;
  userId: string;
  requestId?:string
  workspaceId: string;
  key?:string;
}) => {
  console.log({
    title,
    message,
    userId,
    requestId,
    workspaceId
  });

  if (!userId) return;

  try {
    const newNotification = {
      title,
      message,
      createdAt: serverTimestamp(),
      read: false,
      userId,
      requestId:requestId?requestId:null,
      workspaceId,
      key: key
    };
    const ref = getNotificationRef(userId);
    const docRef = doc(ref);
    await setDoc(docRef, { id: docRef?.id, ...newNotification });
    console.log("Notification inserted successfully");
  } catch (error) {
    console.log("Error inserting notification:", error);
  }
};
