import { Category } from "./categories";
import { Request, User } from "./types";
import { generateId } from "./utils";
import { RequestError, ValidationError } from "./errors";
import { CATEGORIES } from "./categories";
import { storage } from "./storage";

export interface CreateRequestParams {
  title: string;
  category: Category;
  categoryId?: string;
  transcript: string;
  user: User;
  admins: User[];
  adminId?: string | null;
  audioUrl?: string | null;
  officeId?: string;
  workspaceId?: string;
  status?: "Pending" | "In Progress" | "Completed";
  whisperData?: {
    confidence: number;
    segments?: Array<{ text: string; confidence: number }>;
  };
  originalAnalysis?: {
    title: string;
    category: Category;
    aiResponse: string;
  };
  recordingDuration?:number
}

export async function createRequest(params: CreateRequestParams): Promise<{
  request: Request;
}> {
  try {
    // Validate required fields
    if (!(params.title as unknown as string)?.trim()) {
      throw new ValidationError("Title is required");
    }
    if (!(params.category as unknown as string)?.trim()) {
      throw new ValidationError("Category is required");
    }
    if (!(params.transcript as string)?.trim()) {
      throw new ValidationError("Transcript is required");
    }
    if (!params.user?.id) {
      throw new ValidationError("User is required");
    }
    if (!params.audioUrl) {
      throw new ValidationError("Recording is required");
    }
    if (!params.categoryId) {
      throw new ValidationError("Category ID is required");
    }

    const request = {
      title: params.title.trim(),
      category: (params.category as unknown as string).trim(),
      categoryId: params.categoryId,
      transcript: params.transcript.trim(),
      audioUrl: params.audioUrl,
      status: params.status || "Pending",
      createdAt: new Date().toISOString(),
      agentId: params.user.id,
      adminId: params?.adminId || null,
      comments: [],
      whisperData: params.whisperData,
      officeId: params?.officeId,
      isAireponseUpdated :false,
      workspaceId: params.workspaceId as string,
      recordingDuration : params?.recordingDuration,
      originalAnalysis: params.originalAnalysis
        ? {
            title: params.originalAnalysis.title,
            category: params.originalAnalysis.category as unknown as string,
            aiResponse: params?.originalAnalysis?.aiResponse,
          }
        : undefined,
    };
    // Find eligible admin if not an AI request
    if ((params.category as unknown as string) !== CATEGORIES.AI) {
      if (
        !request.adminId &&
        (params.category as unknown as string) !== CATEGORIES.UNCATEGORIZED
      ) {
        const superAdmin = params.admins.find(
          (a) => a.role === "SUPER_ADMIN" && a.email === "tyler@joinvictory.com"
        );
        if (superAdmin) {
          request.adminId = superAdmin.id;
        }
      }
    }

    // Save the request to firestore
    try {
      const requestData = await storage.createRequest({
        request,
        other: {
          admins: params.admins,
          categoryId: params.categoryId,
          officeId: params.user.officeIds[0],
        },
      });
      return { request: requestData };
    } catch (error) {
      console.error("Error saving request:", error);
      throw new RequestError("Failed to save request");
    }
  } catch (error) {
    console.error("Error creating request:", error);
    if (error instanceof RequestError || error instanceof ValidationError) {
      throw error;
    }
    throw new RequestError(
      "Failed to create request. Please try again.",
      "REQUEST_CREATION_FAILED"
    );
  }
}
