import { sendCustomMail } from "@/api/sendEmail";

export const generateEmailVerificationEmailFunction = async ({
  name,
  email,
  id
}: {
  name: string;
  email: string;
  id: string;
}) => {
  try {
    await sendCustomMail({
      body: {
        data:{
            name,
            email,
            id
        },
        template_name: "verify-email",
      },
    });
  } catch (error) {
    console.log("Error sending email to user", error);
    throw error;
  }
};
