import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import EmailVerificationBanner from "../components/EmailVerificationBanner";
import { useAuth } from "@/context/AuthContext";
import clsx from "clsx";

interface DashboardLayoutProps {
  children: React.ReactNode;
  isCollapsed: boolean;
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function DashboardLayout({ children,isCollapsed, setIsCollapsed }: DashboardLayoutProps) {
  const {
    state: { user },
  } = useAuth();
  const showBanner = false;
 
  return (
    <div className="flex bg-gray-100 min-h-screen">
      {showBanner && <EmailVerificationBanner />}
      <Sidebar
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      />
      <div
        className={clsx(
          "flex flex-1 flex-col overflow-hidden pl-16 lg:pl-0 transition-all mb-20"
        )}
      >
        
        <Header otherClasses={!isCollapsed ? "lg:w-[calc(100%-64px)]" : "lg:w-[calc(100%-16rem)]"} />
        <main
          id="main-content"
          className={`flex-1 overflow-y-auto p-4  mt-24 xs:mt-20 pb-16 ${
            showBanner ? "mt-16" : ""
          }`}
        >
          {children}
        </main>
        <Footer otherClasses={!isCollapsed ? "lg:w-[calc(100%-64px)]" : "lg:w-[calc(100%-16rem)]"}  />
      </div>
    </div>
  );
}
