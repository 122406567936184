import { useNavigate } from 'react-router-dom';
import { Activity, MessageSquare, Users } from 'lucide-react';
import { useAuth } from '@/context/AuthContext';
import { useAdmin } from '@/context/AdminContext';
import { useRequests } from '@/context/RequestsContext';
import { cn } from '@/lib/utils';
import UserManagement from '@/components/UserManagement';
import { useEffect } from 'react';

export default function AdminDashboard() {
  const navigate = useNavigate();
  const { state: requestState } = useRequests();
  const { state: { user } } = useAuth();
  const { state: adminState } = useAdmin();

  if (!user) return null;

  const isSuperAdmin = user.role === 'SUPER_ADMIN';

  // Calculate admin-specific stats
  const activeRequests = requestState.requests.filter(request => {
      return request.status !== 'Completed';
  }).length;

  // Only show team members for admins and super admins
  const teamMembers = adminState.users
    .filter(member => {
      if (user.role === 'ADMIN') {
        return member.role === 'AGENT' && member.officeIds.some(id => user.officeIds.includes(id));
      }
      return true;
    })
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

  const teamMembersCount = teamMembers.length;

  // Calculate weekly activity
  const recentRequests = requestState.requests.filter(request => {
    if (isSuperAdmin) return true;
    return request.adminId === user.id;
  }).filter(request => {
    const requestDate = new Date(request.createdAt);
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    return requestDate >= oneWeekAgo;
  }).length;

  const stats = [
    { name: 'Active Requests', value: activeRequests.toString(), icon: MessageSquare },
    { name: 'Team Members', value: teamMembersCount.toString(), icon: Users },
    { name: 'Weekly Activity', value: `${recentRequests} new`, icon: Activity },
  ];

  
useEffect(()=>{

  if(requestState.loadedSuggestion){
    const elem = document.getElementById('requests');
    elem?.scrollIntoView({ behavior: 'smooth' });
  }

},[requestState.loadedSuggestion])

  // Get filtered requests
  const filteredRequests = (requestState.loadedSuggestion ? requestState.suggestedRequests : requestState.requests)
    .sort((a:any, b:any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    .slice(0, 5);

    // console.log({filteredRequests});
    

  const handleRequestClick = (requestId: string) => {
    navigate('/dashboard/requests', { state: { scrollToId: requestId } });
  };

  return (
    <div className="mx-auto max-w-7xl">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">Admin Dashboard</h1>
      </div>

      <div className="mt-8 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map((stat) => (
          <div
            key={stat.name}
            className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
          >
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <stat.icon
                  className="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    {stat.name}
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {stat.value}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-8">
        <UserManagement />
      </div>

      <div className="mt-8" id="requests">
        <h2 className="text-lg font-medium text-gray-900">Recent Requests</h2>
        <div className="mt-4 overflow-hidden rounded-lg bg-white shadow">
          {filteredRequests.length === 0 ? (
            <div className="p-6 text-center text-gray-500">
              No requests to display.
            </div>
          ) : (
            <ul role="list" className="divide-y divide-gray-200">
              {filteredRequests.map((request) => (
                <li 
                  key={request.id}
                  onClick={() => handleRequestClick(request.id)}
                  className="px-4 py-4 hover:bg-gray-50 cursor-pointer transition-colors"
                >
                  <div className="flex flex-col items-start gap-2 sm:gap-0 sm:flex-row sm:items-center justify-between">
                    <div>
                      <p className="font-medium text-gray-900">{request.title}</p>
                      <div className="mt-1 flex flex-col sm:flex-row items-start gap-2 sm:gap-0 sm:items-center sm:space-x-2 text-sm text-gray-500">
                        <span>{request.category}</span>
                        <span className='hidden sm:block'>•</span>
                        <span>{new Date(request.createdAt).toLocaleString()}</span>
                      </div>
                    </div>
                    <span
                      className={cn(
                        'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium',
                        {
                          'bg-blue-100 text-blue-800': request.status === 'In Progress',
                          'bg-yellow-100 text-yellow-800': request.status === 'Pending',
                          'bg-green-100 text-green-800': request.status === 'Completed',
                        }
                      )}
                    >
                      {request.status}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}