import classnames from 'classnames';
import styles from './loader-spinner.module.scss';
interface LoaderSpinnerInterface {
  otherClasses?: string;
}
export default function LoaderSpinner({
  otherClasses,
}: LoaderSpinnerInterface) {
  const loaderSpinnerClasses = classnames(
    otherClasses,
    'w-full items-center justify-center flex py-10',
  );
  return (
    <div className={loaderSpinnerClasses} data-testid="loader-spinner">
      <div className={styles.loader}></div>
    </div>
  );
}