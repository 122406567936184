import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "@/components/ui/Button";
import { MessageSquare, Zap, Clock, Shield } from "lucide-react";
import Footer from "@/components/Footer";

const features = [
  {
    name: "AI-Powered Assistance",
    description:
      "Get instant answers to your real estate questions with our advanced AI technology.",
    icon: Zap,
  },
  {
    name: "Real-Time Support",
    description:
      "Connect with the right department instantly for seamless issue resolution.",
    icon: Clock,
  },
  {
    name: "Secure Communication",
    description:
      "Your conversations and data are protected with enterprise-grade security.",
    icon: Shield,
  },
  {
    name: "Smart Categorization",
    description:
      "Automatically route requests to the right team for faster response times.",
    icon: MessageSquare,
  },
];

export default function Home() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-[#0A0B1A] text-white">
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div className="absolute inset-x-0 -top-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
          <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#4169E1] to-[#9370DB] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" />
        </div>

        <div className="mx-auto max-w-5xl  sm:py-48 lg:py-56">
          <div className="text-center">
            <div className="flex justify-center mb-8">
              <img
                src="/images/victoria.png"
                alt="Victoria"
                className="h-32 w-auto"
                style={{
                  objectFit: "contain",
                  filter: "drop-shadow(0 0 20px rgba(65, 105, 225, 0.3))",
                }}
              />
            </div>
            <p className="mt-12 text-2xl sm:text-3xl leading-relaxed text-gray-300 max-w-4xl mx-auto">
              Your intelligent assistant for streamlined real estate operations.
              Ask anything, get help instantly, and focus on what matters most -
              growing your business.
            </p>
            <div className="mt-16 flex items-center justify-center flex-wrap gap-y-6 gap-x-8">
              <Button
                onClick={() => navigate("/login")}
                size="lg"
                className="text-xl px-12 py-6 bg-[#4169E1] hover:bg-[#3158D0] transition-colors"
              >
                Get Started
              </Button>
              <Button
                variant="outline"
                size="lg"
                className="text-xl px-12 py-6 border-2 text-white"
                onClick={() =>
                  window.open(
                    "https://calendly.com/heyvictoria/30min",
                    "_blank"
                  )
                }
              >
                Schedule a Demo
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-5xl sm:text-6xl font-bold mb-12">
              Everything you need to succeed
            </h2>
            <p className="text-2xl text-gray-300 max-w-4xl mx-auto mb-20">
              Victoria streamlines your daily operations with powerful features
              designed specifically for real estate professionals.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
            {features.map((feature) => (
              <div
                key={feature.name}
                className="relative p-8 bg-white/5 rounded-3xl hover:bg-white/10 transition-colors"
              >
                <div className="flex items-center justify-center w-16 h-16 rounded-2xl bg-[#4169E1]/20 mb-6">
                  <feature.icon className="h-8 w-8 text-[#4169E1]" />
                </div>
                <h3 className="text-xl font-semibold mb-4">{feature.name}</h3>
                <p className="text-gray-400">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Background Gradient */}
      <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
        <div className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#4169E1] to-[#9370DB] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" />
      </div>

      <div className="bg-gray-900 text-gray-400 text-sm py-2 px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <span>© {new Date().getFullYear()} Victoria</span>
          <div>
            <Link to="/privacy-policy" className="mr-4">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
