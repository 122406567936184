import {
  collection,
  doc,
  getDocs,
  setDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  writeBatch,
  getDoc,
} from "firebase/firestore";
import { db } from "./firebase/app";
import { User, Request, Comment, RequestWithoutid } from "./types";
import { getErrorMessage } from "./errors";
import { getAdminsByCategory } from "./permissions";
import { insertNotification } from "./firebase/notification";
import { sendSms } from "@/api/sendSms";

const MAX_RETRY_ATTEMPTS = 3;
const RETRY_DELAY = 1000;

async function retry<T>(
  operation: () => Promise<T>,
  attempts: number = MAX_RETRY_ATTEMPTS,
  context: string = "Operation"
): Promise<T> {
  let lastError: any;
  let delay = RETRY_DELAY;

  for (let i = 0; i < attempts; i++) {
    try {
      return await operation();
    } catch (error: any) {
      lastError = error;

      console.error(
        `[Firebase][${context}] Attempt ${i + 1}/${attempts} failed:`,
        {
          error: {
            code: error.code,
            message: error.message,
            name: error.name,
          },
          context,
          attempt: i + 1,
          maxAttempts: attempts,
        }
      );

      const isRetryableError =
        error?.code === "unavailable" ||
        error?.code === "resource-exhausted" ||
        error?.code === "network-request-failed" ||
        error?.code === "permission-denied";

      if (!isRetryableError || i === attempts - 1) {
        throw error;
      }

      await new Promise((resolve) => setTimeout(resolve, delay));
      delay *= 2;
    }
  }

  throw lastError;
}

export const storage = {
  getCurrentUser: async (): Promise<User | null> => {
    return retry(
      async () => {
        try {
          const usersRef = collection(db, "users");
          const q = query(
            usersRef,
            where("email", "==", "tyler@joinvictory.com")
          );
          const snapshot = await getDocs(q);

          if (snapshot.empty) {
            return null;
          }

          const userData = snapshot.docs[0].data();
          return { id: snapshot.docs[0].id, ...userData } as User;
        } catch (error) {
          console.error("[Firebase][getCurrentUser] Error:", {
            error: getErrorMessage(error),
            context: "getCurrentUser",
          });
          throw error;
        }
      },
      MAX_RETRY_ATTEMPTS,
      "getCurrentUser"
    );
  },

  getRequests: async (): Promise<Request[]> => {
    return retry(
      async () => {
        try {
          const snapshot = await getDocs(collection(db, "requests"));
          return snapshot.docs.map(
            (doc) =>
              ({
                id: doc.id,
                ...doc.data(),
                comments: doc.data().comments || [],
              } as Request)
          );
        } catch (error) {
          console.error("[Firebase][getRequests] Error:", {
            error: getErrorMessage(error),
            context: "getRequests",
          });
          throw error;
        }
      },
      MAX_RETRY_ATTEMPTS,
      "getRequests"
    );
  },

  createRequest: async ({
    request,
    other,
  }: {
    request: RequestWithoutid;
    other: {
      admins: User[];
      categoryId: string;
      officeId: string;
    };
  }): Promise<any> => {
    return retry(
      async () => {
        try {
          const batch = writeBatch(db);
          
          const requestRef = doc(collection(db, `requests`)); 
          const requestData = {
            ...request,
            id: requestRef?.id,
            comments: request.comments || [],
            status: request.status || "Pending",
            createdAt: request.createdAt || new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          };

          batch.set(requestRef, requestData);
          const { admins, categoryId, officeId } = other;
          const eligibleAdmins = getAdminsByCategory(
            admins,
            categoryId,
            officeId
          );
          const allNotificationPromise: any = [];
          eligibleAdmins.forEach((admin) => {
            allNotificationPromise.push(
              insertNotification({
                userId: admin.id,
                title: "New Request Created",
                message: `A new request "${request.title}" has been created and requires your attention.`,
                requestId: requestRef?.id,
                workspaceId:request?.workspaceId,
                key: "request",
              })
            );
          });
          await batch.commit();
          await Promise.all(allNotificationPromise);
          if (request.category === "AI Assistant" && request?.originalAnalysis?.aiResponse) {
            await sendSms({
            body: {
              message: `You have an AI response for Request: ${request.title}. See the response here: https://heyvictoria.ai/dashboard/requests?requestId=${requestRef?.id}`,
              to: request?.agentId,
              from: request?.workspaceId,
              requestId: requestRef?.id,
            }
            })
          }          
          return requestData;
        } catch (error) {
          console.error("[Firebase][createRequest] Error:", {
            error: getErrorMessage(error),
            context: "createRequest",
          });
          throw error;
        }
      },
      MAX_RETRY_ATTEMPTS,
      "createRequest"
    );
  },

  updateRequest: async (request: Request): Promise<Request> => {
    return retry(
      async () => {
        try {
          const batch = writeBatch(db);
          const requestRef = doc(db, "requests", request.id);
          const requestDoc = await getDoc(requestRef);

          if (!requestDoc.exists()) {
            throw new Error(`Request ${request.id} not found`);
          }

          const existingData = requestDoc.data();
          const mergedRequest = {
            ...existingData,
            ...request,
            comments: request.comments || existingData.comments || [],
            updatedAt: new Date().toISOString(),
          };

          batch.update(requestRef, mergedRequest);
          await batch.commit();

          return request;
        } catch (error) {
          console.error("[Firebase][updateRequest] Error:", {
            error: getErrorMessage(error),
            context: "updateRequest",
            requestId: request.id,
          });
          throw error;
        }
      },
      MAX_RETRY_ATTEMPTS,
      "updateRequest"
    );
  },

  deleteRequest: async (id: string): Promise<void> => {
    return retry(
      async () => {
        try {
          const batch = writeBatch(db);
          const requestRef = doc(db, "requests", id);

          batch.delete(requestRef);
          await batch.commit();
        } catch (error) {
          console.error("[Firebase][deleteRequest] Error:", {
            error: getErrorMessage(error),
            context: "deleteRequest",
            requestId: id,
          });
          throw error;
        }
      },
      MAX_RETRY_ATTEMPTS,
      "deleteRequest"
    );
  },

  createComment: async (comment: Comment): Promise<Comment> => {
    return retry(
      async () => {
        try {
          const batch = writeBatch(db);
          const requestRef = doc(db, "requests", comment.requestId);
          const requestDoc = await getDoc(requestRef);

          if (!requestDoc.exists()) {
            throw new Error("Request not found");
          }

          const request = requestDoc.data() as Request;
          const updatedComments = [...(request.comments || []), comment];

          batch.update(requestRef, {
            comments: updatedComments,
            updatedAt: new Date().toISOString(),
          });

          await batch.commit();
          return comment;
        } catch (error) {
          console.error("[Firebase][createComment] Error:", {
            error: getErrorMessage(error),
            context: "createComment",
            requestId: comment.requestId,
            commentId: comment.id,
          });
          throw error;
        }
      },
      MAX_RETRY_ATTEMPTS,
      "createComment"
    );
  },

  getComments: async (requestId: string): Promise<Comment[]> => {
    return retry(
      async () => {
        try {
          const requestDoc = await getDoc(doc(db, "requests", requestId));
          if (!requestDoc.exists()) {
            return [];
          }
          const request = requestDoc.data() as Request;
          return request.comments || [];
        } catch (error) {
          console.error("[Firebase][getComments] Error:", {
            error: getErrorMessage(error),
            context: "getComments",
            requestId,
          });
          throw error;
        }
      },
      MAX_RETRY_ATTEMPTS,
      "getComments"
    );
  },

  getUsers: async (): Promise<User[]> => {
    return retry(
      async () => {
        try {
          const usersRef = collection(db, "users");
          const snapshot = await getDocs(usersRef);
          return snapshot.docs.map(
            (doc) => ({ id: doc.id, ...doc.data() } as User)
          );
        } catch (error) {
          console.error("[Firebase][getUsers] Error:", {
            error: getErrorMessage(error),
            context: "getUsers",
          });
          throw error;
        }
      },
      MAX_RETRY_ATTEMPTS,
      "getUsers"
    );
  },
};
